// Copyright HS Analysis GmbH, 2021
// Author: Sebastian Murgul, Viktor Eberhardt

// Framework imports
import PropTypes from "prop-types";
import React from "react";

// External Imports
import classNames from "classnames";
import { TextValidator } from "react-material-ui-form-validator";

// Material UI imports
import DialogContent from "@mui/material/DialogContent";
import FormHelperText from "@mui/material/FormHelperText";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import withStyles from "@mui/styles/withStyles";

// Icon imports
import SearchIcon from "@mui/icons-material/Search";
import InfoIcon from "@mui/icons-material/Info";

const styles = () => ({
  dialogContent: {
    paddingTop: 0,
    maxWidth: 900,
    height: 562,
  },
  tileBar: {
    whiteSpace: "normal !important",
  },
  projectTile: {
    cursor: "pointer",
    padding: 5,
    overflow: "hidden",
    border: "1px solid black",
    "&:hover": {
      background: "#AAA",
    },
  },
  projectTileSelected: {
    background: "#1BA1E2!important",
  },
  projectTilesList: {
    maxHeight: 437,
    padding: 3,
  },
  expiredMarker: {
    color: "red",
    border: "2px solid red",
    position: "absolute",
    top: 5,
    right: 5,
    zIndex: 1,
    background: "rgba(255,255,255,0.9)",
    padding: "2px 5px 5px 5px",
  },
  expiringMarker: {
    color: "darkorange",
    border: "2px solid darkorange",
    position: "absolute",
    top: 8,
    right: 8,
    zIndex: 1,
    background: "rgba(255,255,255,0.9)",
    padding: "2px 5px 5px 5px",
    lineHeight: "16px",
  },
  infoIconButton: {
    position: "absolute",
    top: 0,
    right: 0,
    zIndex: 1,
  },
});

class Step0 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchText: "",
    };
    this.imageListItemRefs = [];
  }

  onChangeFilter = (e) => {
    let searchText = e.target.value;
    this.setState({ searchText });
    this.imageListItemRefs = [];
  };

  isInView = (el) => {
    if (
      !this.imageList ||
      this.imageList.getBoundingClientRect().height === 0
    ) {
      this.imageList = document.getElementById("ImageList");
      if (
        this.imageList &&
        this.imageList.getAttribute("listener") !== "true"
      ) {
        this.imageList.addEventListener("scroll", this.handleImageListScroll);
      }
    }
    let result = false;
    if (this.imageList && el) {
      let parentTop = this.imageList.getBoundingClientRect().top;
      let parentHeight = this.imageList.getBoundingClientRect().height;
      let elTop = el.getBoundingClientRect().top;
      let elHeight = el.getBoundingClientRect().height;

      result = elTop + elHeight > parentTop && elTop < parentTop + parentHeight;
    }
    return result;
  };

  handleImageListScroll = () => {
    this.forceUpdate();
  };

  openDescription = (availableType) => {
    console.log(availableType);
    window.openInformationDialog(
      availableType.label,
      "",
      availableType.description
    );
  };

  render() {
    const {
      classes,
      availableTypes,
      projectType,
      onProjectType,
      name,
      onChangeName,
      onSaveName,
    } = this.props;

    // Check for selected histo modules.
    let isHistoModule =
      projectType.includes("HistoClassification") ||
      projectType.includes("HistoPointCounting");

    return (
      <DialogContent className={classes.dialogContent}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={8}>
            <TextValidator
              disabled={isHistoModule}
              fullWidth
              errorMessages={["This field is required"]}
              label="Enter project name"
              margin="normal"
              onBlur={(e) => onSaveName(e.target.value)}
              onChange={(e) => onChangeName(e.target.value)}
              style={{ marginTop: 0 }}
              type="text"
              validators={isHistoModule ? [] : ["required"]}
              value={isHistoModule ? "Will be filled in automatically" : name}
              variant="standard"
              // select all text if component is defined
              onFocus={(e) => e.target.select()}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  onSaveName(e.target.value);
                }
              }}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <div style={{ position: "relative" }}>
              <SearchIcon
                style={{
                  display: "block",
                  position: "absolute",
                  top: 0,
                  left: 0,
                  fill: "lightgray",
                  marginTop: 20,
                }}
              />
              <TextField
                autoFocus
                variant="standard"
                fullWidth
                style={{
                  marginLeft: 30,
                  width: "calc(100% - 30px)",
                  marginTop: 0,
                  display: "inline-block",
                }}
                key={"searchText"}
                margin="normal"
                label={"Search Modules"}
                value={this.state.searchText}
                onChange={this.onChangeFilter}
              />
            </div>
          </Grid>
        </Grid>

        <FormHelperText style={{ marginBottom: 10 }}>
          Project Type
        </FormHelperText>
        <ImageList
          id={"ImageList"}
          ref={this.imageListRef}
          rowHeight={215}
          className={classes.projectTilesList}
          spacing={8}
          cols={3}
        >
          {availableTypes
            .filter((row) => {
              let searchContent = row.label.toLowerCase();
              if (row.projectProperties.Hidden) return false;
              let toSearch = this.state.searchText.toLowerCase();
              return searchContent.includes(toSearch);
            })
            .map((availableType) => (
              <Tooltip
                key={availableType.name}
                disableInteractive
                title={
                  availableType.label.length > 25 ? availableType.label : ""
                }
              >
                <ImageListItem
                  ref={(el) => {
                    if (!this.imageListItemRefs[availableType.name]) {
                      this.imageListItemRefs[availableType.name] = el;
                      this.forceUpdate();
                    }
                  }}
                  onClick={() => onProjectType(availableType)}
                  className={classNames(
                    classes.projectTile,
                    projectType === availableType.name &&
                      classes.projectTileSelected
                  )}
                >
                  {availableType.projectProperties.WIP && (
                    <div className={classes.expiringMarker}>WIP</div>
                  )}
                  {availableType.isValid === false &&
                    availableType.expirationDate && (
                      <div className={classes.expiredMarker}>
                        Expired on{" "}
                        {availableType.expirationDate.replace("T00:00:00", "")}!
                      </div>
                    )}
                  {availableType.isValid &&
                    availableType.expiresInDays < 32 && (
                      <div className={classes.expiringMarker}>
                        Expires in {availableType.expiresInDays} days!
                      </div>
                    )}

                  {this.isInView(
                    this.imageListItemRefs[availableType.name]
                  ) && (
                    <img
                      src={`data:image/jpeg;base64,${availableType.image}`}
                      alt={availableType.name}
                      className={classes.tileImage}
                    />
                  )}
                  <ImageListItemBar
                    className={classes.titleBar}
                    title={availableType.label}
                  />
                  <IconButton
                    className={classes.infoIconButton}
                    onClick={() => this.openDescription(availableType)}
                  >
                    <InfoIcon />
                  </IconButton>

                  {/* <div className={classes.tileBar}>{availableType.label}</div> */}
                </ImageListItem>
              </Tooltip>
            ))}
        </ImageList>
      </DialogContent>
    );
  }
}

Step0.propTypes = {
  classes: PropTypes.object.isRequired,
  name: PropTypes.string,
  projectType: PropTypes.string,
  onChangeName: PropTypes.func,
  onSaveName: PropTypes.func,
  availableTypes: PropTypes.array,
  onProjectType: PropTypes.func,
};

export default withStyles(styles)(Step0);
