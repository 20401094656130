import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";

import withStyles from "@mui/styles/withStyles";
import ProteomeSideBar from "./components/ProteomSideBar";
import ProteomResults from "./components/ProteomResults";
import ProteomToolBar from "./components/ProteomToolBar";
import Backend from "../common/utils/Backend";

const styles = {
  outerContainer: {
    overflow: "hidden",
    position: "relative",
    width: "100%",
    height: "calc(100% - 64px)",
    background: "#EBEBEB",
    //background: "blue",
    display: "grid",
    gridTemplateColumns: "auto 50px 456px",
  },
  TableContainer: {
    background: "white",
    marginRight: 5,
    overflow: "auto",
  },
  PreviewContainer: {
    background: "white",
  },
  ToolBarContainer: {
    background: "white",
    margin: "0 5px",
  },
  SideBarContainer: {
    background: "white",
  },
};

class ProteomeViewer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRow: null,
      project: null,
      annotations: {},
      nameChanged: false,
    };
  }

  componentDidMount = () => {
    window.addEventListener("keydown", this.keyDown);
    Backend.loadProject({
      id: this.props.id,
    }).then((project) => {
      if (project.projectData) {
        project.metaData = project.projectData.metaData;
        project.name = project.projectData.name;
      } else {
        project.metaData = JSON.parse(project.metaData);
      }
      window.setNavigationbarTitle(
        `${project.name} (${project.viewerConfig.project.label})`
      );
      this.setState({ project: project });
      Backend.loadAnnotationsObject(
        { id: project.id, fileId: project.files[0].id },
        (result) => {
          if (result.annotations) {
            this.setState({ annotations: result.annotations });
          }
        }
      );
    });
    Backend.getCurrentUser((user) => {
      this.setState({ user: user.fullName });
    });
  };

  componentWillUnmount() {
    window.removeEventListener("keydown", this.keyDown);
  }
  /**
   * handle shortcut
   * @param {ActionEvent} e Event when keyboard button is pressed
   */
  keyDown = (e) => {
    if (e.ctrlKey && e.key === "s") {
      this.saveFunction((result) => console.log("saved:", result));
      e.preventDefault();
    }
  };

  setSelectedRow = (row) => {
    this.setState({ selectedRow: row });
  };

  createProjectModel = () => {
    const { project, user } = this.state;

    const projectModel = {
      name: project.name,
      user: user,
      id: project.id,
      readableId: project.readableId,
      metaData: project.metaData,
      type: project.type,
      tools: project.viewerConfig.project.tools,
      job: project.viewerConfig.project.job,
      files: project.files,
    };
    console.log("projectModel:", projectModel);
    return projectModel;
  };

  saveFunction = (callback) => {
    if (this.state) {
      if (this.state.nameChanged) {
        Backend.renameProject(
          this.state.project.id,
          this.state.project.name,
          () => {
            console.log("project changed!");
          }
        );
      }
      Backend.saveProject(this.createProjectModel(), (data) => {
        if (data.success) {
          window.showSuccessSnackbar("Project saved succesfully!");
          callback(true);
        } else {
          window.showErrorSnackbar("Project couldn't be saved!");
          callback(false);
        }
      });
    }
  };
  saveAndRunProject = () => {
    this.saveFunction((success) => {
      if (success) {
        Backend.setProjectsPending([this.state.project.id], () => {
          this.props.history.push("/");
        });
      }
    });
  };

  onChangeMetaData = (field, e) => {
    this.setState((prevState) => {
      let project = Object.assign({}, prevState.project);
      project.metaData[field] = e;
      return { project };
    });
  };

  changeProjectName = (projectName) => {
    this.setState((prevState) => {
      let project = Object.assign({}, prevState.project);
      project.name = projectName;
      return { project, nameChanged: true };
    });
  };

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.outerContainer}>
        <div className={classes.TableContainer}>
          <ProteomResults
            annotations={this.state.annotations}
            setSelectedRow={this.setSelectedRow}
          />
        </div>
        <div className={classes.ToolBarContainer}>
          {this.state.project && (
            <ProteomToolBar
              onSave={this.saveFunction}
              project={this.state.project}
            />
          )}
        </div>
        <div className={classes.SideBarContainer}>
          <ProteomeSideBar
            project={this.state.project}
            selectedRow={this.state.selectedRow}
            onChangeMetaData={this.onChangeMetaData}
            changeProjectName={this.changeProjectName}
            saveAndRunProject={this.saveAndRunProject}
          />
        </div>
      </div>
    );
  }
}

ProteomeViewer.propTypes = {
  classes: PropTypes.object.isRequired,
  id: PropTypes.string,
  history: PropTypes.array,
};

export default withRouter(withStyles(styles)(ProteomeViewer));
