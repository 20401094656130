// RendererUtils.jsx:
// Utility functions for rendering images and tiles.

// Copyright HS Analysis GmbH, 2024
// Author: Viktor Eberhardt

// Framework imports
import { inv, multiply, transpose } from "mathjs";

// HSA imports
import Backend from "../../common/utils/Backend";

/**
 * Configures an image by applying histogram manipulation based on the provided channel settings.
 *
 * @param {HTMLImageElement} img - The image element to be configured.
 * @param {Object} channel - The channel settings for histogram manipulation.
 * @param {number} channel.min - The minimum value for the channel.
 * @param {number} channel.max - The maximum value for the channel.
 * @param {number} channel.gamma - The gamma correction value for the channel.
 * @returns {HTMLCanvasElement|null} - The canvas element with the configured image, or null if the image is invalid.
 */
export function configureImage(img, channel) {
  if (!img || img.width === 0) {
    return null;
  }

  const canvas = document.createElement("canvas");
  canvas.width = img.width;
  canvas.height = img.height;

  const offScrCan = document.createElement("canvas");
  offScrCan.width = img.width;
  offScrCan.height = img.height;

  const ctx1 = offScrCan.getContext("2d");

  ctx1.drawImage(img, 0, 0, img.width, img.height);

  // do histogram manipulation
  if (img.width === 0) {
    return null;
  }
  const imgData = ctx1.getImageData(0, 0, img.width, img.height);
  const data = imgData.data;
  const step = 4; // rgba
  const min = channel.min;
  const max = channel.max;
  const gamma = channel.gamma;
  const invGamma = 1 / gamma;
  const maxMinusMin = max - min;
  const maxPixelValue = 255;

  // Create Lookup Table
  const lookupTable = new Array(maxPixelValue + 1);
  for (let i = 0; i < 256; i++) {
    let val = Math.min(Math.max(i - min, 0), max);
    val = (255 * val) / maxMinusMin;
    lookupTable[i] = 255 * Math.pow(val / 255, invGamma);
  }

  // Apply lookupTable to each pixel
  for (let i = 0; i < data.length; i += step) {
    // Use lookupTable for R, G, B channels
    data[i] = lookupTable[data[i]]; // Red
    data[i + 1] = lookupTable[data[i + 1]]; // Green
    data[i + 2] = lookupTable[data[i + 2]]; // Blue
    // Alpha channel remains unchanged
  }

  ctx1.putImageData(imgData, 0, 0);
  return offScrCan;
}

var __assign =
  (this && this.__assign) ||
  function () {
    __assign =
      Object.assign ||
      function (t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
          s = arguments[i];
          for (var p in s)
            if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
        }
        return t;
      };
    return __assign.apply(this, arguments);
  };
//function is necessary for procrustes calculation
function SVD(a, options) {
  let _a = __assign({ u: true, v: true, eps: Math.pow(2, -52) }, options),
    withu = _a.u,
    withv = _a.v,
    eps = _a.eps;
  var tol = 1e-64 / eps;
  // throw error if a is not defined
  if (!a) {
    throw new TypeError("Matrix a is not defined");
  }
  // Householder's reduction to bidiagonal form
  var n = a[0].length;
  var m = a.length;
  if (m < n) {
    throw new TypeError("Invalid matrix: m < n");
  }
  var l1, c, f, h, s, y, z;
  var l = 0,
    g = 0,
    x = 0;
  var e = [];
  var u = [];
  var v = [];
  // Initialize u
  for (let i = 0; i < m; i++) {
    u[i] = new Array(n).fill(0);
  }
  // Initialize v
  for (let i = 0; i < n; i++) {
    v[i] = new Array(n).fill(0);
  }
  // Initialize q
  var q = new Array(n).fill(0);
  // Copy array a in u
  for (let i = 0; i < m; i++) {
    for (let j = 0; j < n; j++) {
      u[i][j] = a[i][j];
    }
  }
  for (let i = 0; i < n; i++) {
    e[i] = g;
    s = 0;
    l = i + 1;
    for (let j = i; j < m; j++) {
      s += Math.pow(u[j][i], 2);
    }
    if (s < tol) {
      g = 0;
    } else {
      f = u[i][i];
      g = f < 0 ? Math.sqrt(s) : -Math.sqrt(s);
      h = f * g - s;
      u[i][i] = f - g;
      for (let j = l; j < n; j++) {
        s = 0;
        for (let k = i; k < m; k++) {
          s += u[k][i] * u[k][j];
        }
        f = s / h;
        for (let k = i; k < m; k++) {
          u[k][j] = u[k][j] + f * u[k][i];
        }
      }
    }
    q[i] = g;
    s = 0;
    for (let j = l; j < n; j++) {
      s += Math.pow(u[i][j], 2);
    }
    if (s < tol) {
      g = 0;
    } else {
      f = u[i][i + 1];
      g = f < 0 ? Math.sqrt(s) : -Math.sqrt(s);
      h = f * g - s;
      u[i][i + 1] = f - g;
      for (let j = l; j < n; j++) {
        e[j] = u[i][j] / h;
      }
      for (let j = l; j < m; j++) {
        s = 0;
        for (let k = l; k < n; k++) {
          s += u[j][k] * u[i][k];
        }
        for (let k = l; k < n; k++) {
          u[j][k] = u[j][k] + s * e[k];
        }
      }
    }
    y = Math.abs(q[i]) + Math.abs(e[i]);
    if (y > x) {
      x = y;
    }
  }
  // Accumulation of right-hand transformations
  if (withv) {
    for (let i = n - 1; i >= 0; i--) {
      if (g !== 0) {
        h = u[i][i + 1] * g;
        for (let j = l; j < n; j++) {
          v[j][i] = u[i][j] / h;
        }
        for (let j = l; j < n; j++) {
          s = 0;
          for (let k = l; k < n; k++) {
            s += u[i][k] * v[k][j];
          }
          for (let k = l; k < n; k++) {
            v[k][j] = v[k][j] + s * v[k][i];
          }
        }
      }
      for (let j = l; j < n; j++) {
        v[i][j] = 0;
        v[j][i] = 0;
      }
      v[i][i] = 1;
      g = e[i];
      l = i;
    }
  }
  // Accumulation of left-hand transformations
  if (withu) {
    for (let i = n - 1; i >= 0; i--) {
      l = i + 1;
      g = q[i];
      for (let j = l; j < n; j++) {
        u[i][j] = 0;
      }
      if (g !== 0) {
        h = u[i][i] * g;
        for (let j = l; j < n; j++) {
          s = 0;
          for (let k = l; k < m; k++) {
            s += u[k][i] * u[k][j];
          }
          f = s / h;
          for (let k = i; k < m; k++) {
            u[k][j] = u[k][j] + f * u[k][i];
          }
        }
        for (let j = i; j < m; j++) {
          u[j][i] = u[j][i] / g;
        }
      } else {
        for (let j = i; j < m; j++) {
          u[j][i] = 0;
        }
      }
      u[i][i] = u[i][i] + 1;
    }
  }
  // Diagonalization of the bidiagonal form
  eps = eps * x;
  var testConvergence;
  for (let k = n - 1; k >= 0; k--) {
    for (let iteration = 0; iteration < 50; iteration++) {
      // test-f-splitting
      testConvergence = false;
      for (l = k; l >= 0; l--) {
        if (Math.abs(e[l]) <= eps) {
          testConvergence = true;
          break;
        }
        if (Math.abs(q[l - 1]) <= eps) {
          break;
        }
      }
      if (!testConvergence) {
        // cancellation of e[l] if l>0
        c = 0;
        s = 1;
        l1 = l - 1;
        for (let i = l; i < k + 1; i++) {
          f = s * e[i];
          e[i] = c * e[i];
          if (Math.abs(f) <= eps) {
            break; // goto test-f-convergence
          }
          g = q[i];
          q[i] = Math.sqrt(f * f + g * g);
          h = q[i];
          c = g / h;
          s = -f / h;
          if (withu) {
            for (let j = 0; j < m; j++) {
              y = u[j][l1];
              z = u[j][i];
              u[j][l1] = y * c + z * s;
              u[j][i] = -y * s + z * c;
            }
          }
        }
      }
      // test f convergence
      z = q[k];
      if (l === k) {
        // convergence
        if (z < 0) {
          // q[k] is made non-negative
          q[k] = -z;
          if (withv) {
            for (var j = 0; j < n; j++) {
              v[j][k] = -v[j][k];
            }
          }
        }
        break; // break out of iteration loop and move on to next k value
      }
      // Shift from bottom 2x2 minor
      x = q[l];
      y = q[k - 1];
      g = e[k - 1];
      h = e[k];
      f = ((y - z) * (y + z) + (g - h) * (g + h)) / (2 * h * y);
      g = Math.sqrt(f * f + 1);
      f = ((x - z) * (x + z) + h * (y / (f < 0 ? f - g : f + g) - h)) / x;
      // Next QR transformation
      c = 1;
      s = 1;
      for (let i = l + 1; i < k + 1; i++) {
        g = e[i];
        y = q[i];
        h = s * g;
        g = c * g;
        z = Math.sqrt(f * f + h * h);
        e[i - 1] = z;
        c = f / z;
        s = h / z;
        f = x * c + g * s;
        g = -x * s + g * c;
        h = y * s;
        y = y * c;
        if (withv) {
          for (let j = 0; j < n; j++) {
            x = v[j][i - 1];
            z = v[j][i];
            v[j][i - 1] = x * c + z * s;
            v[j][i] = -x * s + z * c;
          }
        }
        z = Math.sqrt(f * f + h * h);
        q[i - 1] = z;
        c = f / z;
        s = h / z;
        f = c * g + s * y;
        x = -s * g + c * y;
        if (withu) {
          for (let j = 0; j < m; j++) {
            y = u[j][i - 1];
            z = u[j][i];
            u[j][i - 1] = y * c + z * s;
            u[j][i] = -y * s + z * c;
          }
        }
      }
      e[l] = 0;
      e[k] = f;
      q[k] = x;
    }
  }
  // Number below eps should be zero
  for (let i = 0; i < n; i++) {
    if (q[i] < eps) q[i] = 0;
  }
  return { u: u, q: q, v: v };
}

function calculateAffineTransformation(opointBase, opointShift, bfact, tfact) {
  //copy arrays
  let pointBase = JSON.parse(JSON.stringify(opointBase));
  let pointShift = JSON.parse(JSON.stringify(opointShift));

  for (let i = 0; i < pointBase.length; i++) {
    pointShift[i][0] = pointShift[i][0] * tfact;
    pointShift[i][1] = pointShift[i][1] * tfact;
    pointBase[i][0] = pointBase[i][0] * bfact;
    pointBase[i][1] = pointBase[i][1] * bfact;
  }

  //procrustes Calculation
  let n = pointBase.length;
  let ny = pointShift.length;

  let muXSumX = 0;
  let muXSumY = 0;
  for (let i = 0; i < n; i++) {
    muXSumX += pointBase[i][0];
    muXSumY += pointBase[i][1];
  }
  let muYSumX = 0;
  let muYSumY = 0;
  for (let i = 0; i < ny; i++) {
    muYSumX += pointShift[i][0];
    muYSumY += pointShift[i][1];
  }
  let muX = [muXSumX / n, muXSumY / n];
  let muY = [muYSumX / ny, muYSumY / ny];

  let x0 = [];
  let y0 = [];
  for (let i = 0; i < n; i++) {
    x0.push([pointBase[i][0] - muX[0], pointBase[i][1] - muX[1]]);
  }
  for (let i = 0; i < ny; i++) {
    y0.push([pointShift[i][0] - muY[0], pointShift[i][1] - muY[1]]);
  }

  let muXSumXQ = 0;
  let muXSumYQ = 0;
  for (let i = 0; i < n; i++) {
    muXSumXQ += x0[i][0] ** 2;
    muXSumYQ += x0[i][1] ** 2;
  }
  let muYSumXQ = 0;
  let muYSumYQ = 0;
  for (let i = 0; i < ny; i++) {
    muYSumXQ += y0[i][0] ** 2;
    muYSumYQ += y0[i][1] ** 2;
  }
  let ssX = muXSumXQ + muXSumYQ;
  let ssY = muYSumXQ + muYSumYQ;

  let normX = Math.sqrt(ssX);
  let normY = Math.sqrt(ssY);

  let newX0 = [];
  let newY0 = [];
  for (let i = 0; i < n; i++) {
    newX0.push([x0[i][0] / normX, x0[i][1] / normX]);
  }
  for (let i = 0; i < ny; i++) {
    newY0.push([y0[i][0] / normY, y0[i][1] / normY]);
  }

  let a = multiply(transpose(newX0), newY0);
  let { u, q, v } = SVD(a);

  let U = JSON.parse(JSON.stringify(u));
  U[0][0] = u[0][1];
  U[0][1] = u[0][0];
  U[1][0] = u[1][1];
  U[1][1] = u[1][0];
  let Vt = JSON.parse(JSON.stringify(v));
  Vt[0][0] = v[0][1];
  Vt[0][1] = v[0][0];
  Vt[1][0] = v[1][1];
  Vt[1][1] = v[1][0];
  let s = JSON.parse(JSON.stringify(q));
  s[0] = q[1];
  s[1] = q[0];

  let V = transpose(Vt);

  let T = multiply(V, transpose(U));
  // reflection always at best
  // scaling always true
  // view python code for details
  let traceTA = 0;
  for (let i = 0; i < s.length; i++) {
    traceTA += s[i];
  }
  let b = (traceTA * normX) / normY;
  let Z = multiply(newY0, T);

  for (let i = 0; i < Z.length; i++) {
    Z[i][0] = normX * traceTA * Z[i][0] + muX[0];
    Z[i][1] = normX * traceTA * Z[i][1] + muX[1];
  }
  let c = multiply(muY, T);
  c[0] = muX[0] - c[0] * b;
  c[1] = muX[1] - c[1] * b;
  let R = [
    [T[0][0], T[0][1], 0],
    [T[1][0], T[1][1], 0],
    [0, 0, 1],
  ];
  let S = [
    [b, 0, 0],
    [0, b, 0],
    [0, 0, 1],
  ];
  let t = [
    [1, 0, c[0]],
    [0, 1, c[1]],
    [0, 0, 1],
  ];
  let Matrix = transpose(multiply(multiply(R, S), transpose(t)));
  return Matrix;
}

function calcOnloading(params, img) {
  img.onload = () => {
    params.counter += 1;
    params.imgs.push(img);
    if (params.counter === 2) {
      let str = params.imgs[0].currentSrc;
      //let baseHeight = null;
      let targetHeight = null;
      if (str.includes(params.baseId)) {
        //baseHeight = params.imgs[0].height;
        targetHeight = params.imgs[1].height;
      } else {
        return;
      }

      let bfact = 1; // baseHeight / params.baseOme.sizeY;
      let tfact = 1;
      let translateScale = targetHeight / params.ome.sizeY; //baseHeight / params.baseOme.sizeY;

      let m = calculateAffineTransformation(
        params.pointBase,
        params.pointShift,
        bfact,
        tfact
      );
      let hMat = [
        m[0][0],
        m[0][1],
        m[0][2],
        m[1][0],
        m[1][1],
        m[1][2],
        m[2][0],
        m[2][1],
        m[2][2],
      ];
      params.callback(hMat, translateScale);
    }
  };
}

export function calculateTransformationMatrix(
  pointBase,
  pointShift,
  ome,
  baseId,
  targetId,
  baseOme,
  callback
) {
  let ids = [baseId, targetId];
  let counter = 0;
  let imgs = [];
  let params = {
    counter,
    imgs,
    pointBase,
    pointShift,
    ome,
    baseOme,
    callback,
    baseId,
  };
  for (let i = 0; i < ids.length; i++) {
    let img = new Image();
    img.src = Backend.renderRegion({
      id: ids[i],
      page: 0,
      lv: 0,
      x: 0,
      y: 0,
    });
    calcOnloading(params, img);
  }
}

function onloading(p, i, j, visImg) {
  visImg.onload = () => {
    p.counter += 1;
    p.imgs.push(visImg);
    p.pos.push([j - p.xStart, i - p.yStart]);
    if (p.counter === p.tilesToLoad) {
      p.callback(p.imgs, p.pos);
    }
  };
}

function tileLoader(params) {
  for (let i = params.yStart; i <= params.yEnd; i++) {
    for (let j = params.xStart; j <= params.xEnd; j++) {
      let visImg = new Image();
      visImg.src = Backend.renderRegion({
        id: params.fileId,
        page: params.page,
        lv: params.lv,
        x: j,
        y: i,
      });
      onloading(params, i, j, visImg);
    }
  }
}

function transformPoint(x, y, m) {
  let a = m[0][0] * x + m[0][1] * y + m[0][2];
  let b = m[1][0] * x + m[1][1] * y + m[1][2];
  let c = m[2][0] * x + m[2][1] * y + m[2][2];
  if (c !== 1) {
    console.log("transformation error");
  }
  let resultX = a / c;
  let resultY = b / c;
  return [resultX, resultY];
}

export function tileRegistration(
  img,
  tileId,
  hMat,
  fact,
  channel,
  callbackPushImage
) {
  let cv = window.cv;
  let splited = tileId.split(",");
  let page = parseInt(splited[0]);
  let lv = parseInt(splited[1]);
  let x = parseInt(splited[2]);
  let y = parseInt(splited[3]);
  let fileId = splited[4];
  let maxTile = Math.pow(2, lv);
  //let strin = "-" + page + "," + lv + "," + x + "," + y + "-";
  let m = [
    [hMat[0], hMat[1], hMat[2] * fact],
    [hMat[3], hMat[4], hMat[5] * fact],
    [hMat[6], hMat[7], hMat[8]],
  ];

  let iM = inv(m);
  let s = [
    [maxTile, 0, 0],
    [0, maxTile, 0],
    [0, 0, 1],
  ];
  m = multiply(multiply(s, m), inv(s));
  iM = inv(m);

  //get tile coordinates
  let tileWidth = img.width;
  let tileHeight = img.height;
  let xPosition = (x + 1) * tileWidth;
  let yPosition = (y + 1) * tileHeight;

  let pointTL = [xPosition - tileWidth, yPosition - tileHeight];
  let pointTR = [xPosition, yPosition - tileHeight];
  let pointBL = [xPosition - tileWidth, yPosition];
  let pointBR = [xPosition, yPosition];

  //reverse transfrom the tile coordinates
  let invPointTL = transformPoint(pointTL[0], pointTL[1], iM);
  let invPointTR = transformPoint(pointTR[0], pointTR[1], iM);
  let invPointBL = transformPoint(pointBL[0], pointBL[1], iM);
  let invPointBR = transformPoint(pointBR[0], pointBR[1], iM);

  //create rectangle around transformed coordinates
  let minX = Math.min(
    invPointTL[0],
    invPointTR[0],
    invPointBL[0],
    invPointBR[0]
  );
  let minY = Math.min(
    invPointTL[1],
    invPointTR[1],
    invPointBL[1],
    invPointBR[1]
  );
  let maxX = Math.max(
    invPointTL[0],
    invPointTR[0],
    invPointBL[0],
    invPointBR[0]
  );
  let maxY = Math.max(
    invPointTL[1],
    invPointTR[1],
    invPointBL[1],
    invPointBR[1]
  );

  let rectPointTL = [minX, minY];
  let rectPointBR = [maxX, maxY];
  let rectWidth = Math.abs(maxX - minX);
  let rectHeight = Math.abs(maxY - minY);

  //create mats and use them to calc needed transformation matrix
  let OriginRectPointTL = [
    invPointTL[0] - rectPointTL[0],
    invPointTL[1] - rectPointTL[1],
  ];
  let OriginRectPointTR = [
    invPointTR[0] - rectPointTL[0],
    invPointTR[1] - rectPointTL[1],
  ];
  let OriginRectPointBL = [
    invPointBL[0] - rectPointTL[0],
    invPointBL[1] - rectPointTL[1],
  ];
  let OriginRectPointBR = [
    invPointBR[0] - rectPointTL[0],
    invPointBR[1] - rectPointTL[1],
  ];

  let matSource = [
    OriginRectPointTL[0],
    OriginRectPointTL[1],
    OriginRectPointTR[0],
    OriginRectPointTR[1],
    OriginRectPointBL[0],
    OriginRectPointBL[1],
    OriginRectPointBR[0],
    OriginRectPointBR[1],
  ];

  matSource = cv.matFromArray(4, 1, cv.CV_32FC2, matSource);
  let matTarget = [0, 0, tileWidth, 0, 0, tileHeight, tileWidth, tileHeight];
  matTarget = cv.matFromArray(4, 1, cv.CV_32FC2, matTarget);
  let newMat = cv.getPerspectiveTransform(matSource, matTarget);

  //calculate wich tiles need to be loaded for complete information
  let xStart = x;
  let yStart = y;
  let xEnd = x;
  let yEnd = y;
  if (rectPointTL[0] < pointTL[0] && pointTL[0] !== 0) {
    let diff = pointTL[0] - rectPointTL[0];
    diff = diff / tileWidth;
    xStart = xStart - (Math.trunc(diff) + 1);
    if (xStart < 0) xStart = 0;
  }
  if (rectPointBR[0] > pointBR[0]) {
    let diff = rectPointBR[0] - pointBR[0];
    diff = diff / tileWidth;
    xEnd = xEnd + (Math.trunc(diff) + 1);
    if (xEnd > maxTile - 1) xEnd = maxTile - 1;
  }
  if (rectPointTL[1] < pointTL[1] && pointTL[1] !== 0) {
    let diff = pointTL[1] - rectPointTL[1];
    diff = diff / tileHeight;
    yStart = yStart - (Math.trunc(diff) + 1);
    if (yStart < 0) yStart = 0;
  }
  if (rectPointBR[1] > pointBR[1]) {
    let diff = rectPointBR[1] - pointBR[1];
    diff = diff / tileHeight;
    yEnd = yEnd + (Math.trunc(diff) + 1);
    if (yEnd > maxTile - 1) yEnd = maxTile - 1;
  }

  //create offline canvas
  let offScrCan = document.createElement("canvas");
  offScrCan.width = (xEnd - xStart + 1) * tileWidth;
  offScrCan.height = (yEnd - yStart + 1) * tileHeight;
  let offsetX = 0;
  let offsetY = 0;

  //adjust canvas size
  if (rectPointTL[0] - pointTL[0] < 0) {
    offScrCan.width = offScrCan.width + Math.abs(rectPointTL[0] - pointTL[0]);
    offsetX = Math.abs(rectPointTL[0] - pointTL[0]);
  }
  if (rectPointBR[0] - pointBR[0] > (xEnd - xStart + 1) * tileWidth) {
    offScrCan.width = offScrCan.width + Math.abs(rectPointBR[0] - pointBR[0]);
  }
  if (rectPointTL[1] - pointTL[1] < 0) {
    offScrCan.height = offScrCan.height + Math.abs(rectPointTL[1] - pointTL[1]);
    offsetY = Math.abs(rectPointTL[1] - pointTL[1]);
  }
  if (rectPointBR[1] - pointBR[1] > (yEnd - yStart + 1) * tileHeight) {
    offScrCan.height = offScrCan.height + Math.abs(rectPointBR[1] - pointBR[1]);
  }

  let ctx = offScrCan.getContext("2d");
  //load the tiles wich will be later drawn into offline canvas
  let imgs = [];
  let pos = [];
  let counter = 0;
  let tilesToLoad = (yEnd - yStart + 1) * (xEnd - xStart + 1);
  //tileloaderparams
  let tileLoaderParams = {
    yStart,
    yEnd,
    xStart,
    xEnd,
    fileId,
    page,
    lv,
    tilesToLoad,
    counter,
    imgs,
    pos,
    callback: (imgs, pos) => {
      if (imgs.length === pos.length) {
        for (let i = 0; i < imgs.length; i++) {
          let p = pos[i];
          ctx.drawImage(
            imgs[i],
            p[0] * tileWidth + offsetX,
            p[1] * tileHeight + offsetY,
            tileWidth,
            tileHeight
          );
        }
      } else {
        console.log("RETURN ERROR");
        return;
      }
      //create rectangle with information from offline canvas
      let imgData = ctx.getImageData(
        rectPointTL[0] - xStart * tileWidth + offsetX,
        rectPointTL[1] - yStart * tileHeight + offsetY,
        rectWidth,
        rectHeight
      );
      //transform the rectangle
      let src = cv.matFromImageData(imgData);
      let dst = new cv.Mat();
      let dsize = new cv.Size(tileWidth, tileHeight);
      cv.warpPerspective(
        src,
        dst,
        newMat,
        dsize,
        cv.INTER_NEAREST,
        cv.BORDER_REPLICATE
      );

      let newImgData = new ImageData(
        new Uint8ClampedArray(dst.data),
        dst.cols,
        dst.rows
      );
      //create new canvas in tile size and draw transformation result into it
      let offTileCan = document.createElement("canvas");
      offTileCan.width = tileWidth;
      offTileCan.height = tileHeight;
      let ctxTile = offTileCan.getContext("2d");
      ctxTile.putImageData(newImgData, 0, 0);
      //console.log("REGISTERED", page, lv, x, y, fileId);
      // ctxTile.font = "100px Arial";
      // ctxTile.fillStyle = "red";
      // ctxTile.textAlign = "center";
      // ctxTile.fillText(
      //   strin,
      //   parseInt(tileWidth / 2),
      //   parseInt(tileHeight / 2)
      // );
      offTileCan = configureImage(offTileCan, channel);

      callbackPushImage(offTileCan);
    },
  };
  tileLoader(tileLoaderParams);
}

// function autoRegistration(
//   pointBase,
//   pointShift,
//   ome,
//   fileId,
//   activeFileId,
//   splitIds,
//   callback
// ) {
//   //fileIdBase, fileIdShift
//   let cv = window.cv;
//   let idBase = fileId;
//   let idShift = fileId;
//   if (fileId !== activeFileId) {
//     idBase = activeFileId;
//     idShift = fileId;
//   } else {
//     idBase = splitIds[0];
//     idShift = splitIds[1];
//   }

//   // let ids = [fileIdBase, fileIdShift];
//   let ids = [idBase, idShift];
//   let counter = 0;
//   let imgs = [];

//   for (let i = 0; i < ids.length; i++) {
//     let img = new Image();
//     img.src = Backend.renderRegion({
//       id: ids[i],
//       page: 0,
//       lv: 0,
//       x: 0,
//       y: 0,
//     });
//     img.onload = () => {
//       counter += 1;
//       imgs.push(img);
//       if (counter === 2) {
//         console.log("Images loaded");
//         // let factor = 2;
//         // //alot of code just to get imgs as mats

//         // //Image Base
//         // let offScrnBase = document.createElement("canvas");
//         // offScrnBase.width = imgs[0].width;
//         // offScrnBase.height = imgs[0].height;
//         // let ctxBase = offScrnBase.getContext("2d");
//         // ctxBase.drawImage(imgs[0], 0, 0, offScrnBase.width, offScrnBase.height);
//         // let imgDataBase = ctxBase.getImageData(
//         //   0,
//         //   0,
//         //   offScrnBase.width,
//         //   offScrnBase.height
//         // );
//         // let srcBase = cv.matFromImageData(imgDataBase);

//         // //Image Shift
//         // let offScrnShift = document.createElement("canvas");
//         // offScrnShift.width = imgs[1].width;
//         // offScrnShift.height = imgs[1].height;

//         // let ctxShift = offScrnShift.getContext("2d");
//         // ctxShift.drawImage(
//         //   imgs[1],
//         //   0,
//         //   0,
//         //   offScrnShift.width,
//         //   offScrnShift.height
//         // );
//         // let imgDataShift = ctxShift.getImageData(
//         //   0,
//         //   0,
//         //   offScrnShift.width,
//         //   offScrnShift.height
//         // );
//         // let srcShift = cv.matFromImageData(imgDataShift);

//         // //now we have imgs as mats

//         // // convert 2 grayscale
//         // let imgBaseGray = new cv.Mat();
//         // let imgShiftGray = new cv.Mat();
//         // cv.cvtColor(srcBase, imgBaseGray, cv.COLOR_BGRA2GRAY);
//         // cv.cvtColor(srcShift, imgShiftGray, cv.COLOR_BGRA2GRAY);

//         // //resize images
//         // let shiftShrinkWidth = parseInt(offScrnShift.width / factor);
//         // let shiftShrinkHeight = parseInt(offScrnShift.height / factor);
//         // let imgBaseGrayShrink = new cv.Mat();
//         // let imgShiftGrayShrink = new cv.Mat();
//         // cv.resize(
//         //   imgBaseGray,
//         //   imgBaseGrayShrink,
//         //   new cv.Size(
//         //     parseInt(offScrnBase.width / factor),
//         //     parseInt(offScrnBase.height / factor)
//         //   ),
//         //   cv.INTER_NEAREST
//         // );
//         // cv.resize(
//         //   imgShiftGray,
//         //   imgShiftGrayShrink,
//         //   new cv.Size(shiftShrinkWidth, shiftShrinkHeight),
//         //   cv.INTER_NEAREST
//         // );

//         // //Feature detection and matrix creation
//         // var t0 = performance.now();

//         // let kp1 = new cv.KeyPointVector();
//         // let kp2 = new cv.KeyPointVector();
//         // let dscrpt1 = new cv.Mat();
//         // let dscrpt2 = new cv.Mat();
//         // let orb = new cv.ORB(5000);

//         // orb.detectAndCompute(imgBaseGrayShrink, new cv.Mat(), kp1, dscrpt1);
//         // orb.detectAndCompute(imgShiftGrayShrink, new cv.Mat(), kp2, dscrpt2);

//         // let goodMatches = new cv.DMatchVector();
//         // let matcher = new cv.BFMatcher(cv.NORM_HAMMING, true);
//         // let matches = new cv.DMatchVector();

//         // matcher.match(dscrpt1, dscrpt2, matches);

//         // //let qualityCounter = 0;
//         // // for (let i = 0; i < matches.size(); i++) {
//         // //   if (matches.get(i).distance < 45) {
//         // //     qualityCounter += 1;
//         // //     goodMatches.push_back(matches.get(i));
//         // //   }
//         // // }
//         // // while (goodMatches.size() < matches.size() * 0.9) {
//         // //   for (let i = 0; i < matches.size(); i++) {
//         // //     if (matches.get(i).distance < cntr) {
//         // //       goodMatches.push_back(matches.get(i));
//         // //     }
//         // //   }
//         // //   cntr += 10;
//         // // }
//         // // top 90%
//         // // let arr = [];
//         // // for (let i = 0; i < matches.size(); i++) {
//         // //   arr.push([i, matches.get(i).distance]);
//         // // }
//         // // arr.sort((a, b) => {
//         // //   if (a[1] === b[1]) {
//         // //     return 0;
//         // //   } else {
//         // //     return a[1] < b[1] ? -1 : 1;
//         // //   }
//         // // });

//         // // for (let i = 0; i < matches.size() * 0.9; i++) {
//         // //   goodMatches.push_back(matches.get(arr[i][0]));
//         // // }

//         // let points1 = [];
//         // let points2 = [];
//         // for (let i = 0; i < goodMatches.size(); i++) {
//         //   points1.push(kp1.get(goodMatches.get(i).queryIdx).pt.x);
//         //   points1.push(kp1.get(goodMatches.get(i).queryIdx).pt.y);
//         //   points2.push(kp2.get(goodMatches.get(i).trainIdx).pt.x);
//         //   points2.push(kp2.get(goodMatches.get(i).trainIdx).pt.y);
//         // }
//         // var mat1 = new cv.Mat(points1.length, 1, cv.CV_32FC2);
//         // mat1.data32F.set(points1);
//         // var mat2 = new cv.Mat(points2.length, 1, cv.CV_32FC2);
//         // mat2.data32F.set(points2);

//         // let h = cv.findHomography(mat2, mat1, cv.RANSAC);

//         // //get warped Image for comparison
//         // let dst = new cv.Mat();
//         // let dsize = new cv.Size(shiftShrinkWidth, shiftShrinkHeight);
//         // cv.warpPerspective(srcShift, dst, h, dsize);

//         // //check mif result is good
//         // let kp1Check = new cv.KeyPointVector();
//         // let kp2Check = new cv.KeyPointVector();
//         // let dscrpt1Check = new cv.Mat();
//         // let dscrpt2Check = new cv.Mat();
//         // let orbCheck = new cv.ORB(5000);

//         // orbCheck.detectAndCompute(
//         //   imgBaseGrayShrink,
//         //   new cv.Mat(),
//         //   kp1Check,
//         //   dscrpt1Check
//         // );
//         // orbCheck.detectAndCompute(dst, new cv.Mat(), kp2Check, dscrpt2Check);

//         // //let goodMatchesCheck = new cv.DMatchVector();
//         // let matcherCheck = new cv.BFMatcher(cv.NORM_HAMMING, true);
//         // let matchesCheck = new cv.DMatchVector();

//         // matcherCheck.match(dscrpt1Check, dscrpt2Check, matchesCheck);

//         // // let qualityCounterCheck = 0;
//         // // for (let i = 0; i < matchesCheck.size(); i++) {
//         // //   if (matchesCheck.get(i).distance < 20) {
//         // //     qualityCounterCheck += 1;
//         // //   }
//         // // }
//         // if (matchesCheck.size() < matches.size() * 0.4) {
//         //   console.log("probably failed");
//         // }

//         // /*
//         // warp
//         //   diff = newImgData imgDataBase
//         //   if diff > x
//         //     do it again
//         //     parameterset [0,1,2,3,4...]
//         //     if still
//         //       return "automatic reg not suitable"

//         // */

//         // var t1 = performance.now();
//         // console.log("Exec Time " + (t1 - t0) / 1000 + " seconds.");

//         // let m = [
//         //   [h.data64F[0], h.data64F[1], h.data64F[2]],
//         //   [h.data64F[3], h.data64F[4], h.data64F[5]],
//         //   [h.data64F[6], h.data64F[7], h.data64F[8]],
//         // ];
//         // let s = [
//         //   [factor, 0, 0],
//         //   [0, factor, 0],
//         //   [0, 0, 1],
//         // ];
//         // m = multiply(multiply(s, m), inv(s));
//         let m = calculateAffineTransformation(pointBase, pointShift, ome);
//         let hMat = [
//           m[0][0],
//           m[0][1],
//           m[0][2],
//           m[1][0],
//           m[1][1],
//           m[1][2],
//           m[2][0],
//           m[2][1],
//           m[2][2],
//         ];
//         callback(hMat);
//       }
//     };
//   }
// }

/**
 * Processes an image by applying histogram manipulation and optionally coloring the alpha channel.
 *
 * @param {HTMLImageElement} img - The image to be processed.
 * @param {Object} channel - The channel configuration for processing.
 * @param {number} channel.min - The minimum value for histogram manipulation.
 * @param {number} channel.max - The maximum value for histogram manipulation.
 * @param {number} channel.gamma - The gamma correction value.
 * @param {string} channel.type - The type of the channel (e.g., "brightfield").
 * @param {string} channel.color - The color to be applied to the alpha channel.
 * @returns {HTMLCanvasElement|null} - The processed image as a canvas element, or null if the input image is invalid.
 */
export function colorInImage(img, channel) {
  if (!img || img.width === 0) {
    return null;
  }

  const offScrCan = document.createElement("canvas");
  offScrCan.width = img.width;
  offScrCan.height = img.height;

  const ctx1 = offScrCan.getContext("2d");

  ctx1.drawImage(img, 0, 0, img.width, img.height);

  // do histogram manipulation
  if (img.width === 0) return null;
  const imgData = ctx1.getImageData(0, 0, img.width, img.height);
  const step = 4; // rgba
  const min = channel.min;
  const max = channel.max;
  const gamma = channel.gamma;
  const maxPixelValue = 255;
  const rgb = false;

  // Create lookup table to increase performance
  const lookupTable = new Array(maxPixelValue + 1);
  for (let i = 0; i <= maxPixelValue; i++) {
    lookupTable[i] =
      (Math.min(Math.max(i - min, 0), max) * maxPixelValue) / (max - min);
    lookupTable[i] =
      maxPixelValue * (lookupTable[i] / maxPixelValue) ** (1 / gamma);
  }

  for (let i = 0, n = imgData.data.length; i < n; i += step) {
    const val = imgData.data[i];
    imgData.data[i + (step - 1)] = lookupTable[val];
  }

  ctx1.putImageData(imgData, 0, 0);

  if (channel.type !== "brightfield" && !rgb) {
    // color in alpha channel
    ctx1.globalCompositeOperation = "source-in";
    ctx1.fillStyle = channel.color;
    ctx1.fillRect(0, 0, img.width, img.height);
  }
  return offScrCan;
}
