// Copyright HS Analysis GmbH, 2024
// Author: Viktor Eberhardt

// Framework imports
import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

// Material UI imports
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import withStyles from "@mui/styles/withStyles";

// Icon imports
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";

// HSA imports
import { withAllViewerContexts } from "../contexts/AllViewerContexts";
import Backend from "../../common/utils/Backend";

// define the component's styling
const styles = () => ({
  fileTreeView: {
    width: "100%",
    margin: 0,
    background: "#fff",
    position: "relative",
  },
  sidebarHandle: {
    position: "absolute",
    right: 0,
    top: 0,
    bottom: 0,
    width: 5,
    background: "#EBEBEB",
    cursor: "pointer",
    "&:hover": {
      background: "#0673C1",
    },
  },
  closeButton: {
    position: "absolute",
    right: 5,
    top: 5,
  },
  expensionSummary: {
    marginTop: 0,
    marginBottom: 0,
    background: "#ff0000",
  },
  labelWrap: {
    paddingRight: 15,
    width: "100%",
    textOverflow: "ellipsis",
    overflow: "hidden",
    display: "inline-block",
  },
  previewImageContainer: {
    position: "relative",
  },
  importText: {
    padding: 5,
    fontWeight: "bold",
    lineHeight: "45px",
    display: "inline-block",
  },
  lineThrough1: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    pointerEvents: "none",
    background:
      "linear-gradient(to bottom right, transparent calc(50% - 1px), red, transparent calc(50% + 1px) )",
  },
  lineThrough2: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    pointerEvents: "none",
    background:
      "linear-gradient(to top right, transparent calc(50% - 1px), red, transparent calc(50% + 1px) )",
  },
  previewImageNotSelected: {
    border: "1px solid #ccc",
  },
  previewImageSelected: {
    border: "3px solid #0673C1!important",
  },
  previewImageInSplitscreen: {
    border: "3px dashed #0673C1!important",
  },
  fileItem: {
    paddingTop: 10,
    paddingBottom: 10,
  },
  sceneItem: {
    position: "relative",
    display: "inline-block",
    width: "fit-content",
    paddingLeft: "5px",
    objectFit: "contain",
    // border: "1px solid #ccc!important",
    cursor: "pointer",
    "& button": {
      padding: 0,
      visibility: "hidden",
      zIndex: 999999,
      width: 0,
      height: 0,
    },
    "&:hover": {
      boxShadow: "1px 1px 2px #333",
      "& button": {
        visibility: "visible",
        zIndex: 999999999999,
      },
    },
  },
  divider: {
    paddingLeft: 5,
    paddingRight: 15,
  },
  filesList: {
    height: "calc(100vh - 518px)",
    overflowY: "auto",
    overflowX: "hidden",
  },
  excludeAllContainer: {
    position: "absolute",
    top: 0,
    right: 0,
  },
  excludeButton: {
    position: "absolute",
    top: 0,
    right: 0,
  },
  filterTextField: {
    marginTop: 0,
    width: "calc(100% - 16px)",
    display: "inline-block",
  },
});

class FileTreeView extends React.Component {
  constructor(props) {
    super(props);
    this.overflowListRef = React.createRef(); // Create a ref object
    this.state = {
      missingThumbnails: {},
      currentSceneFilterIdx: 0,
      filterText: "",
    };
    this.imageListItemRefs = [];
  }

  /**
   * Handles the change event of the filter input.
   * Updates the state with the new filter text and resets the imageListItemRefs array.
   *
   * @param {Event} e - The change event object.
   */
  onChangeFilter(e) {
    this.setState({ filterText: e.target.value });
    this.imageListItemRefs = [];
  }

  /**
   * Handles the scroll event for the file tree view.
   *
   * @param {Event} e - The scroll event object.
   */
  handleScroll = (e) => {
    this.props.persistentStorage.save("files_scroll", e.target.scrollTop);
    this.forceUpdate(); // force update to triggere the isInView check
  };

  /**
   * Lifecycle method called after the component has been mounted to the DOM.
   * It adds an event listener for scroll events and scrolls to the element
   * corresponding to the given `fileId` prop.
   */
  componentDidMount() {
    this._isMounted = true;
    this.overflowListRef.current.addEventListener("scroll", this.handleScroll);

    const { fileGroups } = this.props.projectContext;
    const { fileId } = this.props;
    const fileNames = Object.keys(fileGroups).sort();
    const fileIdIdx = fileNames.findIndex((fn) => {
      return fileGroups[fn].some((file) => file.id === fileId);
    });
    if (fileIdIdx > -1) {
      const el = this.imageListItemRefs[fileIdIdx];
      this.scrollToElement(el);
    }
  }

  /**
   * Called immediately before a component is unmounted and destroyed.
   * Perform any necessary cleanup in this method, such as canceling network requests,
   * removing event listeners, or cleaning up any subscriptions.
   */
  componentWillUnmount() {
    this._isMounted = false;
    this.overflowListRef.current.removeEventListener(
      "scroll",
      this.handleScroll
    );
    if (this.imageList) {
      this.imageList.removeEventListener("scroll", this.forceUpdate());
    }
  }

  /**
   * Scrolls the image list to the specified element.
   * @param {HTMLElement} el - The element to scroll to.
   */
  scrollToElement = (el) => {
    // similar logic as before for imageList initialization
    if (
      !this.imageList ||
      this.imageList.getBoundingClientRect().height === 0
    ) {
      this.imageList = document.getElementById("SceneImageList");
      if (
        this.imageList &&
        this.imageList.getAttribute("listener") !== "true" &&
        this._isMounted
      ) {
        this.imageList.addEventListener("scroll", this.forceUpdate());
      }
    }

    // execution only proceeds if both imageList and el exist
    if (this.imageList && el) {
      const parentPos = this.imageList.getBoundingClientRect(); // save parent position
      const elPos = el.getBoundingClientRect(); // save element position

      const offset = elPos.top - parentPos.top; // calculate offset
      this.imageList.scrollTop = this.imageList.scrollTop + offset; // adjust scroll position
    }
  };

  /**
   * Checks if any of the files in the given array have not been excluded from the scene.
   *
   * @param {Array} files - The array of files to check.
   * @returns {boolean} - Returns true if at least one file has not been excluded, false otherwise.
   */
  hasNotExcludedScenes = (files) => {
    const result = files.some((file) => !file.excludeScene);
    return result;
  };

  /**
   * Checks if an element is within the view of the image list.
   *
   * @param {HTMLElement} el - The element to check.
   * @returns {boolean} - True if the element is within the view, false otherwise.
   */
  isInView = (el) => {
    if (
      !this.imageList ||
      this.imageList.getBoundingClientRect().height === 0
    ) {
      this.imageList = document.getElementById("SceneImageList");
      if (
        this.imageList &&
        this.imageList.getAttribute("listener") !== "true" &&
        this._isMounted
      ) {
        this.imageList.addEventListener("scroll", this.forceUpdate());
      }
    }
    let result = false;
    if (this.imageList && el) {
      let parentTop = this.imageList.getBoundingClientRect().top;
      let parentHeight = this.imageList.getBoundingClientRect().height;
      let elTop = el.getBoundingClientRect().top;
      let elHeight = el.getBoundingClientRect().height;

      result = elTop + elHeight > parentTop && elTop < parentTop + parentHeight;
    }
    return result;
  };

  /**
   * Returns the count of included scenes in the project.
   * @returns {number} The count of included scenes.
   */
  getIncludedScenesCount = () => {
    const { project } = this.props;
    let count = 0;
    for (let file of project.files) {
      if (!file.excludeScene) {
        count++;
      }
    }
    return count;
  };

  render() {
    const { currentSceneFilterIdx } = this.state;
    const {
      classes,
      project,
      onSelectFile,
      fileId,
      onExcludeFilesToggle,
      splitscreenFileIds,
      projectContext,
    } = this.props;
    const { fileGroups } = projectContext;
    const relativePaths = Object.values(fileGroups)
      .map((fileGroup) => {
        return fileGroup[0].relativePath.replace(/\\/g, "/");
      })
      .sort();

    // count the number of files in each folder path and remove duplicate paths
    let folderPaths = relativePaths.reduce((acc, filePath) => {
      let directory = filePath.substring(0, filePath.lastIndexOf("/"));

      if (!acc[directory]) {
        acc[directory] = 1;
      } else {
        acc[directory] += 1;
      }

      return acc;
    }, {});

    const fileNames = Object.keys(fileGroups).sort();
    let selectedPath = "";
    for (let scenes of Object.values(fileGroups)) {
      for (let file of scenes) {
        if (fileId === file.id) {
          selectedPath = file.sourcePath;
        }
      }
    }

    let maxSceneIdx = 0;
    for (let file of project.files) {
      if (file.scene > maxSceneIdx) {
        maxSceneIdx = file.scene;
      }
    }
    const includedScenesCount = this.getIncludedScenesCount();

    return (
      <Grid item className={classes.fileTreeView}>
        {project && (
          <Grid item xs={12}>
            <Tooltip
              disableInteractive
              title={
                <>
                  <h5>Folders in Project</h5>
                  <table>
                    <thead>
                      <tr>
                        <th>Folder Path</th>
                        <th>Number of Files</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Object.entries(folderPaths).map(([key, value]) => {
                        return (
                          <tr key={key}>
                            <td>{key}</td>
                            <td style={{ textAlign: "center" }}>{value}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </>
              }
            >
              <Typography variant="h6" style={{ fontSize: "18px" }}>
                {"Files (" +
                  includedScenesCount +
                  "/" +
                  this.props.project.files.length +
                  "):"}
              </Typography>
            </Tooltip>
            <TextField
              className={classes.filterTextField}
              variant="standard"
              fullWidth
              key={"filterText"}
              margin="normal"
              label={"Filter File Names"}
              value={this.state.filterText}
              onChange={(e) => this.onChangeFilter(e)}
            />
            <div className={classes.excludeAllContainer}>
              {maxSceneIdx > 0 && (
                <Tooltip
                  title={
                    "Include only scene " +
                    ((currentSceneFilterIdx + 1) % (maxSceneIdx + 1)) +
                    " of each file!"
                  }
                >
                  <IconButton
                    style={{ color: "#757575" }}
                    size="small"
                    onClick={() => {
                      const projectsToExclude = project.files.filter((file) => {
                        return (
                          file.excludeScene !== true &&
                          file.scene !== currentSceneFilterIdx
                        );
                      });
                      const projectsToInclude = project.files.filter((file) => {
                        return (
                          file.excludeScene === true &&
                          file.scene === currentSceneFilterIdx
                        );
                      });
                      onExcludeFilesToggle(projectsToExclude, true);
                      onExcludeFilesToggle(projectsToInclude, false);
                      if (maxSceneIdx > currentSceneFilterIdx) {
                        this.setState({
                          currentSceneFilterIdx: currentSceneFilterIdx + 1,
                        });
                      } else {
                        this.setState({ currentSceneFilterIdx: 0 });
                      }
                    }}
                  >
                    <div
                      style={{
                        width: 20,
                        height: 20,
                        border: "2px solid rgb(102, 102, 102)",
                        borderRadius: "50%",
                        textAlign: "center",
                        lineHeight: "14px",
                        fontWeight: "bold",
                      }}
                    >
                      {currentSceneFilterIdx + 1}
                    </div>
                  </IconButton>
                </Tooltip>
              )}

              <Tooltip
                disableInteractive
                title={
                  this.hasNotExcludedScenes(project.files)
                    ? "Exclude all from analysis"
                    : "Include all to analysis"
                }
              >
                <IconButton
                  style={{ color: "#757575" }}
                  size="small"
                  onClick={() => {
                    const toExclude = this.hasNotExcludedScenes(project.files);
                    console.log("project.files:", project.files);
                    onExcludeFilesToggle(
                      project.files.filter(
                        (file) => file.excludeScene !== toExclude
                      ),
                      toExclude
                    );
                  }}
                >
                  {this.hasNotExcludedScenes(project.files) ? (
                    <RemoveCircleIcon />
                  ) : (
                    <AddCircleIcon />
                  )}
                </IconButton>
              </Tooltip>
            </div>

            <List
              id={"SceneImageList"}
              ref={this.overflowListRef}
              className={classes.filesList}
            >
              {fileNames
                .filter((fileName) => {
                  return fileName
                    .toLowerCase()
                    .includes(this.state.filterText.toLowerCase());
                })
                .map((fn, fn_index) => (
                  <React.Fragment key={fn_index}>
                    <div
                      ref={(el) => {
                        if (!this.imageListItemRefs[fn_index]) {
                          this.imageListItemRefs[fn_index] = el;
                        }
                      }}
                      className={classes.fileItem}
                      style={{
                        marginTop: fn_index === 0 && 0,
                        background:
                          fn === selectedPath ? "rgba(0, 0, 0, 0.08)" : "none",
                      }}
                    >
                      {this.isInView(this.imageListItemRefs[fn_index]) ? (
                        <React.Fragment>
                          <Tooltip
                            title={"Path: " + fileGroups[fn][0].relativePath}
                          >
                            <Typography
                              noWrap={true}
                              className={classes.labelWrap}
                            >
                              {fileGroups[fn][0].fileName}
                            </Typography>
                          </Tooltip>
                          <div>
                            {fileGroups[fn]
                              .sort((a, b) => a.scene - b.scene)
                              .map((sceneFile, f_id) => (
                                <div
                                  key={f_id}
                                  className={classes.sceneItem}
                                  style={{
                                    display: sceneFile.sceneName
                                      ? "block"
                                      : "inline-block",
                                    margin: 10,
                                    width: sceneFile.sceneName
                                      ? "inherit"
                                      : "fit-content",
                                  }}
                                >
                                  <Tooltip
                                    disableInteractive
                                    placement="top"
                                    title={
                                      !sceneFile.excludeScene
                                        ? "Exclude from analysis"
                                        : "Include in analysis"
                                    }
                                  >
                                    <IconButton
                                      className={classes.excludeButton}
                                      style={{ color: "#757575" }}
                                      size="small"
                                      onClick={() =>
                                        onExcludeFilesToggle(
                                          [sceneFile],
                                          !sceneFile.excludeScene
                                        )
                                      }
                                    >
                                      {!sceneFile.excludeScene ? (
                                        <RemoveCircleIcon />
                                      ) : (
                                        <AddCircleIcon />
                                      )}
                                    </IconButton>
                                  </Tooltip>
                                  <Tooltip
                                    disableInteractive
                                    key={f_id}
                                    title={"Scene " + (sceneFile.scene + 1)}
                                  >
                                    <div
                                      className={classes.previewImageContainer}
                                      onClick={() => onSelectFile(sceneFile.id)}
                                    >
                                      <div
                                        className={
                                          classNames(
                                            (fileId === sceneFile.id &&
                                              classes.previewImageSelected) ||
                                              (splitscreenFileIds.includes(
                                                sceneFile.id
                                              ) &&
                                                classes.previewImageInSplitscreen)
                                          ) ||
                                          (fileId !== sceneFile.id &&
                                            classes.previewImageNotSelected)
                                        }
                                      >
                                        {this.state.missingThumbnails[
                                          sceneFile.id
                                        ] && (
                                          <div className={classes.importText}>
                                            import
                                          </div>
                                        )}
                                        {sceneFile.sceneName ? (
                                          <span>{sceneFile.sceneName}</span>
                                        ) : (
                                          <img
                                            draggable="true"
                                            onDragStart={(e) => {
                                              e.dataTransfer.setData(
                                                "text/plain",
                                                sceneFile.id
                                              );
                                            }}
                                            className={classes.previewImage}
                                            height="45"
                                            src={Backend.renderThumbnail(
                                              sceneFile.id
                                            )}
                                            onError={() => {
                                              let missingThumbnails =
                                                this.state.missingThumbnails;
                                              missingThumbnails[
                                                sceneFile.id
                                              ] = true;
                                              this.setState({
                                                missingThumbnails:
                                                  missingThumbnails,
                                              });
                                            }}
                                            alt=""
                                          />
                                        )}
                                      </div>
                                      <div
                                        style={{
                                          display: !sceneFile.excludeScene
                                            ? "none"
                                            : "block",
                                        }}
                                        className={classes.lineThrough1}
                                      ></div>
                                      <div
                                        style={{
                                          display: !sceneFile.excludeScene
                                            ? "none"
                                            : "block",
                                        }}
                                        className={classes.lineThrough2}
                                      ></div>
                                    </div>
                                  </Tooltip>
                                </div>
                              ))}
                          </div>
                        </React.Fragment>
                      ) : (
                        <div style={{ height: 78 }}></div>
                      )}
                    </div>
                    {fn_index < fileNames.length - 1 && (
                      <Divider className={classes.divider} />
                    )}
                  </React.Fragment>
                ))}
            </List>
          </Grid>
        )}
      </Grid>
    );
  }
}

// define the component's interface
FileTreeView.propTypes = {
  classes: PropTypes.object.isRequired,
  fileId: PropTypes.string,
  onExcludeFilesToggle: PropTypes.func,
  onSelectFile: PropTypes.func,
  persistentStorage: PropTypes.object,
  project: PropTypes.object,
  projectContext: PropTypes.object,
  splitscreenFileIds: PropTypes.array,
};

export default withAllViewerContexts(withStyles(styles)(FileTreeView));
