//Importing necessary modules
import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import * as echarts from "echarts"; // updated import here

//Functional Component
const ProjectsTimeLineChart = ({
  selectedProjects,
  selectedTargetColumnHeadline,
  projectData,
}) => {
  const myChart = useRef(null);
  const option = useRef({
    grid: {
      right: "25%", // legend width
    },
    xAxis: {
      type: "category",
      axisLabel: {
        rotate: 45, // Rotate labels by 45 degrees.
        formatter: function (value) {
          return value.length > 10 ? value.slice(0, 10) + "..." : value;
        },
      },
      name: "Project",
      data: [],
    },
    yAxis: {
      type: "value",
      name: "Number of Objects",
    },
    toolbox: {
      show: true,
      feature: {
        mark: { show: true },
        dataView: { show: true, readOnly: false },
        saveAsImage: { show: true },
      },
    },
    legend: {
      data: [],
      selected: {},
      type: "scroll", // scrollable legend
      orient: "vertical",
      right: 0,
      top: 40,
    },

    series: [],
  });
  useEffect(() => {
    // Function to run when either of the props change
    const updateChart = () => {
      if (myChart.current === null) {
        myChart.current = echarts.init(document.getElementById("main"));
        myChart.current.setOption(option.current);
      }
      if (
        !selectedTargetColumnHeadline ||
        !selectedProjects ||
        selectedProjects.length === 0 ||
        !projectData ||
        !projectData[selectedProjects[0].id] ||
        Object.keys(projectData).length !== selectedProjects.length ||
        Object.keys(projectData).length === 0
      ) {
        return;
      }
      const longestCombindeResults = Object.values(projectData).reduce(
        (acc, project) => {
          if (project.combinedResultRows.length > acc.length) {
            acc = project.combinedResultRows;
          }
          return acc;
        },
        []
      );
      const legendLabels = longestCombindeResults.map((row) => row[1]);
      option.current.yAxis.name = selectedTargetColumnHeadline;
      option.current.xAxis.data = selectedProjects.map(
        (project) => project.readableId
      );

      if (
        option.current.legend.data.length !== legendLabels.length ||
        option.current.legend.data.some(
          (label) => !legendLabels.includes(label)
        )
      ) {
        option.current.legend.data = legendLabels;
        option.current.legend.selected = legendLabels.reduce((acc, label) => {
          acc[label] = true;
          return acc;
        }, {});
      } else {
        option.current.legend.selected = {
          ...myChart.current.getOption().legend[0].selected,
        };
      }
      option.current.series = legendLabels.map((label, index) => {
        const series = {
          name: label,
          type: "line",
          data: [],
        };
        selectedProjects.forEach((project) => {
          const rowData = projectData[project.id];
          if (!rowData) return;
          const combinedResults = rowData.combinedResultRows;
          const columnHeadlines = rowData.columnHeadlines;
          const targetColumnIndex = columnHeadlines.indexOf(
            selectedTargetColumnHeadline
          );
          const targetColumnData = combinedResults.map(
            (row) => row[targetColumnIndex]
          );
          series.data.push(targetColumnData[index]);
        });
        return series;
      });
      if (option.current.series.length === 0) {
        return;
      }

      option.current.tooltip = {
        trigger: "axis",
        formatter: function (params) {
          const dataIndex = params[0].dataIndex;
          let xAxisLabel =
            params[0].axisValue + ": " + selectedProjects[dataIndex].name;

          return (
            xAxisLabel +
            "<br/>" +
            params.reduce((acc, param) => {
              acc += `<br/>${param.seriesName}: ${param.value}`;
              return acc;
            }, "")
          );
        },
      };
      myChart.current.setOption(option.current);
    };

    // Check if all props are defined before running the function
    if (selectedProjects && selectedTargetColumnHeadline && projectData) {
      updateChart();
    }
  }, [selectedProjects, selectedTargetColumnHeadline, projectData]); // Dependency array
  //The useEffect hook runs after the component output has been rendered to the DOM.

  return <div id="main" style={{ width: 600, height: 400 }}></div>;
};

ProjectsTimeLineChart.propTypes = {
  selectedProjects: PropTypes.arrayOf(PropTypes.object),
  selectedTargetColumnHeadline: PropTypes.string,
  projectData: PropTypes.object,
};

export default ProjectsTimeLineChart;
