// Copyright HS Analysis GmbH, 2024
// Author: Viktor Eberhardt

// Framework imports
import React from "react";
import PropTypes from "prop-types";
import Datasheet from "react-datasheet";
import XLSX from "xlsx";
import { withRouter } from "react-router-dom";

// Material UI imports
import { makeStyles } from "@mui/styles";
import Button from "@mui/material/Button";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Paper from "@mui/material/Paper";

// HSA imports
import Backend from "../common/utils/Backend";
import "react-datasheet/lib/react-datasheet.css";

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    height: "calc(100% - 64px)",
    "& .data-editor": {
      height: "24px !important",
      boxSizing: "border-box !important",
    },
    "& .value-viewer": {
      padding: "0 10px !important",
    },
    display: "grid",
    gridTemplateRows: "auto auto 1fr",
    overflow: "hidden",
  },
  resultTabContent: {
    height: "100%",
    overflowY: "auto",
    display: "block",
  },
  tabContentInner: {
    height: "calc(100% - 16px)",
  },
  defaultPaper: {
    margin: "10px auto",
    padding: "5px",
    width: "auto",
    maxWidth: "calc(100% - 20px)",
    overflow: "auto",
    height: "100%",
  },
  chartImage: {
    width: "100%",
    display: "block",
    margin: "0px",
    objectFit: "contain",
  },
}));

const Report = ({ id }) => {
  const [activeTab, setActiveTab] = React.useState(0);
  const [charts, setCharts] = React.useState([]);
  const [workbook, setWorkbook] = React.useState(null);
  const [arraySheets, setArraySheets] = React.useState(null);
  const settings = { tempPath: "" };

  React.useEffect(() => {
    let isMounted = true;

    const chartFolderPath = "/" + id + "/charts/";
    Backend.walkProjectDirFiles(chartFolderPath, (charts) => {
      if (isMounted) {
        setCharts(charts);
      }
    });

    if (location.pathname.includes("/report/")) {
      Backend.loadProject({ id: id })
        .then((project) => {
          if (isMounted) {
            window.setNavigationbarTitle(
              `Results: ${project.name} (${project.viewerConfig.project.label})`
            );
          }
        })
        .catch((error) => {
          console.info("Error loading project:", error);
        });
    }

    let download_path = Backend.downloadReport(id);
    let url = window.location.origin + download_path;

    /* set up async GET request */
    let req = new XMLHttpRequest();
    req.open("GET", url, true);
    req.responseType = "arraybuffer";

    req.onload = () => {
      let data = new Uint8Array(req.response);
      let workbook = XLSX.read(data, { type: "array" });

      if (
        workbook.SheetNames.length === 1 &&
        workbook.SheetNames[0] === "Sheet1" &&
        workbook.Sheets.Sheet1.A1.v.toLowerCase().startsWith("error")
      ) {
        window.showErrorSnackbar(workbook.Sheets.Sheet1.A1.v);
      }

      let sheets = workbook.SheetNames.map((sheet_name) =>
        XLSX.utils.sheet_to_json(workbook.Sheets[sheet_name], {
          header: 1,
          raw: true,
        })
      );
      for (let i = 0; i < sheets.length; i++) {
        for (let j = 0; j < sheets[i].length; j++) {
          for (let k = 0; k < sheets[i][j].length; k++) {
            if (typeof sheets[i][j][k] === "undefined") {
              sheets[i][j][k] = "";
            }
          }
        }
      }
      const abc = [...Array(26)].map((val, i) => String.fromCharCode(i + 65));
      sheets = sheets.map((sheet) => {
        let rowLength = 0;
        let lastContentIdx = 0;
        for (let i = 0; i < sheet.length; i++) {
          if (sheet[i].length > 0) lastContentIdx = i;
          if (sheet[i].length > rowLength) rowLength = sheet[i].length;
        }
        sheet = sheet.filter((item, idx) => idx <= lastContentIdx);
        return sheet.map((row) => {
          row = row.map((cell) => {
            if (typeof cell === "number") {
              // if (sheet[0][i] && sheet[0][i].includes("%")) {
              //   cell *= 100;
              // }
              if (cell % 1 > 0 && cell < 1) {
                cell = cell.toFixed(6);
              } else if (cell % 1 > 0) {
                // Check if float precision exists
                cell = cell.toFixed(2);
              }
            } else if (
              typeof cell === "string" &&
              cell.includes("Ausreißer ignorieren")
            ) {
              cell = "";
            }
            return { value: cell };
          });
          while (row.length < rowLength) {
            row.push({ value: "" });
          }
          return row;
        });
      });
      for (let sheet of sheets) {
        let addRow = new Array(sheet[0].length);
        addRow = sheet[0].map((v, i) => {
          return { value: abc[i], readOnly: true };
        });
        sheet = sheet.unshift(addRow);
      }
      for (let sheet of sheets) {
        for (let [index, row] of sheet.entries()) {
          row = row.unshift({
            value: index === 0 ? "" : index,
            readOnly: true,
          });
        }
      }

      if (isMounted) {
        setWorkbook(workbook);
        setArraySheets(sheets);
      }
    };

    req.send();

    return () => {
      isMounted = false;
    };
  }, [id]);

  const handleChange = (event, value) => {
    setActiveTab(value);
  };

  const classes = useStyles();
  const isMergedReport = location.pathname.includes("/merged_report/");
  let downloadInfo =
    "(All Excel files are saved here: " + settings.tempPath + "reports/)";
  if (isMergedReport) {
    downloadInfo =
      "Can also be found here: " +
      settings.tempPath +
      "reports/mergedReports/" +
      id +
      "/";
  }

  return (
    <div className={classes.root}>
      <div>
        <Button color="primary" href={Backend.downloadReport(id)} download>
          Download Excel File
        </Button>
        <span>{downloadInfo}</span>
      </div>
      <Tabs
        variant="fullWidth"
        indicatorColor="primary"
        textColor="primary"
        value={activeTab}
        onChange={handleChange}
      >
        {workbook &&
          workbook.SheetNames.map((tab_name, index) => (
            <Tab key={index} label={tab_name} />
          ))}
        {charts.length > 0 && <Tab key={99} label="Charts" />}
      </Tabs>
      <div className={classes.resultTabContent}>
        {arraySheets &&
          arraySheets.map((array_sheet, index) => (
            <div
              key={index}
              className={classes.tabContentInner}
              style={{
                display: index === activeTab ? "block" : "none",
              }}
            >
              <Paper className={classes.defaultPaper}>
                <Datasheet
                  className={"SheetTab" + index}
                  data={array_sheet}
                  valueRenderer={(cell) => cell.value}
                  onCellsChanged={() => {
                    return;
                  }}
                  overflow="clip"
                />
              </Paper>
            </div>
          ))}
        {arraySheets && (
          <div
            key={199}
            className={classes.tabContentInner}
            style={{
              display: arraySheets.length === activeTab ? "block" : "none",
            }}
          >
            <Paper className={classes.defaultPaper}>
              {charts.map((chart, idx) => (
                <img
                  key={idx}
                  className={classes.chartImage}
                  src={Backend.renderReportChart(chart.path)}
                  alt={chart.path}
                />
              ))}
            </Paper>
          </div>
        )}
      </div>
    </div>
  );
};

Report.propTypes = {
  id: PropTypes.string,
};

export default withRouter(Report);
