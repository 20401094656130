// Framework imports
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { ValidatorForm } from "react-material-ui-form-validator";

// External packages
import { Button, Dialog, DialogTitle, MobileStepper } from "@mui/material";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";

// HSA modules
import Backend from "../../common/utils/Backend";
import { viewerType } from "../../common/utils/Utils";
import { authenticationService } from "../../common/services";
import Step0 from "./CreateProjectdialogComponents/Step0";
import Step1 from "./CreateProjectdialogComponents/Step1";
import Step2 from "./CreateProjectdialogComponents/Step2";

class CreateProjectDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      submitted: false,
      activeStep: 0,
      projectTypes: [],
      formData: {
        name: props.newdefaultname,
        projectType: "",
        metaData: {},
        files: [],
        projectProperties: {},
        projectStringProperties: {},
      },
    };

    Backend.loadAvailableProjectTypes((projectTypes) => {
      const { formData } = this.state;
      // order project types by order attribute
      projectTypes = projectTypes.sort((a, b) => a.order - b.order);
      Backend.getLicensingInfo((license) => {
        if (license.activeModules.length > 0) {
          if (license.licenseStatus !== "VALID") {
            authenticationService.logout();
            this.props.history.push("/licensing");
            return;
          }

          projectTypes = projectTypes.map((projectType) => {
            if (license.activeModules.length > 0) {
              let activeModule = license.activeModules.find(
                (x) => x.name === projectType.name
              );
              if (activeModule) {
                projectType.expirationDate = activeModule.expirationDate;
                projectType.isValid = !activeModule.isExpired;
                projectType.expiresInDays = activeModule.expiresInDays;
              } else {
                projectType.isValid = false;
              }
            } else {
              projectType.isValid = true;
            }
            return projectType;
          });

          projectTypes = projectTypes.filter(
            (projectType) => typeof projectType.expirationDate !== "undefined"
          );
          if (projectTypes.length > 0) {
            // select default project type
            let firstValidProjectType = projectTypes.find((pt) => pt.isValid);
            if (firstValidProjectType) {
              formData.projectType = firstValidProjectType.name;
              for (let metaField of projectTypes.find(
                (c) => c.name === formData.projectType
              ).metaData) {
                // use existing value or init dict entry with empty string
                formData.metaData[metaField] =
                  metaField === "Datum" || metaField === "Date"
                    ? this.getDate()
                    : formData.metaData[metaField] || "";
              }
            }
          }
        }

        // write received info into state
        this.setState({
          projectTypes: projectTypes,
          formData,
          metaData: {},
          files: [],
        });
      });
    });
  }

  componentDidUpdate(prevProps) {
    // on open dialog
    if (prevProps.open !== this.props.open && this.props.open) {
      const { formData } = this.state;
      if (this.props.project) {
        formData.files = [
          ...new Set(this.props.project.files.map((file) => file.relativePath)),
        ];
        formData.name = this.props.project.name;
        formData.projectType = this.props.project.type;
      } else {
        formData.files = [];
        formData.name = this.props.newdefaultname;
      }
      this.setState({
        submitted: false,
        activeStep: 0,
        formData,
      });
    }
  }

  /**
   * Defines what happens when the next button is pressed.
   * When moving a step forward, checks are made to see if the next option is necessary.
   * When finishing the final step, project is created or updated.
   */
  handleNext = () => {
    if (this.props.project) {
      this.props.onClose();
    }
    const { formData } = this.state;
    if (this.state.activeStep >= 2) {
      // last step -> submit data
      this.setState({ submitted: true });
      if (this.props.project) {
        //this.onSaveName(this.props.project.name);
        Backend.updateProjectFiles(this.props.project.id, formData, (e) => {
          this.setState({ submitted: false });
          this.handleClose();
          this.props.history.push("/view/" + e.projectId);
        });
      } else {
        if (
          formData.projectType.includes("HistoClassification") ||
          formData.projectType.includes("HistoPointCounting")
        ) {
          let name = formData.files[0];
          name = name.replace(/\\/g, "/");
          let name_array = name.split("/");
          name = name_array[name_array.length - 1];
          name = name.split(".")[0];
          name_array = name.split("-");
          let i = 0;
          while (i < name_array.length - 1) {
            if (i === 0) {
              name = name_array[i];
            } else {
              name = name + "-" + name_array[i];
            }
            i = i + 1;
          }

          formData.name = name;
          this.setState({ formData: formData });
        }
        Backend.createProject(this.state.formData, (e) => {
          this.setState({ submitted: false });
          this.handleClose();
          if (formData.projectType.includes("ProteomeAnalysis")) {
            Backend.loadProject({
              id: e.projectId,
            }).then((project) => {
              Backend.getCurrentUser((user) => {
                const projectModel = {
                  name: project.name,
                  user: user.fullName,
                  id: project.id,
                  readableId: project.readableId,
                  metaData: JSON.parse(project.metaData),
                  type: project.type,
                  tools: project.viewerConfig.project.tools,
                  job: project.viewerConfig.project.job,
                  files: project.files,
                };
                Backend.saveProject(projectModel, (data) => {
                  if (data.success) {
                    this.props.handle_active_tab_change(0);
                    Backend.setProjectsPending([project.id], () => {
                      console.log("job ready to start!");
                    });
                  }
                });
              });
            });
          } else {
            this.props.history.push(
              viewerType(formData.projectType) + e.projectId
            );
          }
        });
      }
    } else {
      let nextStep = this.state.activeStep + 1;
      if (formData.projectType === "") {
        window.openWarningDialog("Please select a Project Module!");
        return;
      }

      if (nextStep === 1 && Object.keys(formData.metaData).length === 0) {
        nextStep++;
      }
      this.setState({ activeStep: nextStep, formData });
    }
  };

  handleBack = () => {
    const { formData } = this.state;
    if (this.state.activeStep === 0) {
      this.props.onClose();
    } else {
      let prevStep = this.state.activeStep - 1;

      if (prevStep === 1 && Object.keys(formData.metaData).length === 0) {
        prevStep--;
      }
      formData.files = [];
      this.setState({ activeStep: prevStep, formData: formData });
    }
  };

  handleClose = () => {
    this.props.onClose();
  };

  uploadFile() {
    Backend.uploadFile(
      this.state.files[0],
      (e) => {
        this.setState({ completed: e });
      },
      () => {
        this.handleClose();
      }
    );
  }

  onChangeName = (e) => {
    const { formData } = this.state;
    formData.name = e;
    this.setState({ formData });
    if (this.props.project) {
      let project = this.props.project;
      project.name = e;
    }
  };

  onSaveName = () => {
    if (this.props.project) {
      Backend.renameProject(
        this.props.project.id,
        this.props.project.name,
        () => {
          console.log("project changed!");
        }
      );
    }
  };

  /**
   * Fills in project parameters for saving.
   * Checks for valid expiration date and gives expiration notice.
   * @param {Object} e Selected module from step 0, including properties.
   */
  onProjectType = (e) => {
    let formData = this.state.formData;
    formData.projectProperties = e.projectProperties;
    formData.projectStringProperties = e.ProjectStringProperties;
    formData.metaData = e.metaData;
    formData.toolsInProject = e.toolsInProject;
    this.setState({ formData: formData });

    if (!e.isValid && e.expirationDate) {
      window.openWarningDialog(
        "This Project Module expired on " +
          e.expirationDate.replace("T00:00:00", "") +
          ".\nPlease contact HS Analysis GmbH to updated the licence!"
      );
      return;
    }
    if (this.props.project) {
      window.openWarningDialog(
        "Project already created and the Project Type can't be changed afterwards.\nPlease create a new Project instead."
      );
    } else {
      const { formData, projectTypes } = this.state;
      formData.projectType = e.name;
      formData.metaData = {};

      let selectedProjectType = projectTypes.find(
        (pt) => pt.name === formData.projectType
      );
      if (
        selectedProjectType &&
        selectedProjectType.name.includes("ProteomeAnalysis")
      ) {
        formData.projectStringProperties =
          selectedProjectType.projectStringProperties;
        formData.projectProperties = selectedProjectType.projectProperties;
        for (const [key, value] of Object.entries(
          formData.projectStringProperties
        )) {
          formData.metaData[key] = value.split(",")[0];
        }
        for (const [key, value] of Object.entries(formData.projectProperties)) {
          formData.metaData[key] = value;
        }
      }
      for (let metaField of this.state.projectTypes.find(
        (c) => c.name === e.name
      ).metaData) {
        // use existing value or init dict entry with empty string
        formData.metaData[metaField] =
          metaField === "Datum" || metaField === "Date"
            ? this.getDate()
            : formData.metaData[metaField] || "";

        // check if metaField ia also key in projectStringProperties
        if (selectedProjectType.projectStringProperties[metaField]) {
          formData.metaData[metaField] =
            selectedProjectType.projectStringProperties[metaField];
        }
      }
      this.setState({ formData });
    }
  };

  onChangeMetaData = (field, e) => {
    const { formData } = this.state;
    if (e === null) {
      // delete field
      delete formData.metaData[field];
    } else {
      // update fiel value
      formData.metaData[field] = e;
    }
    this.setState({ formData });
  };

  onChangeFiles = (e) => {
    const { formData } = this.state;
    formData.files = e;
    this.setState({ formData });
  };

  getDate = () => {
    let today = new Date();
    let dd = String(today.getDate()).padStart(2, "0");
    let mm = String(today.getMonth() + 1).padStart(2, "0");
    let yyyy = today.getFullYear();
    today = dd + "." + mm + "." + yyyy;
    return today;
  };

  render() {
    // const { ...other } = this.props;
    const { activeStep, formData, projectTypes, submitted } = this.state;

    return (
      <Dialog onClose={this.handleClose} open={this.props.open} maxWidth="lg">
        <DialogTitle>Create New Project</DialogTitle>
        <ValidatorForm onSubmit={this.handleNext} style={{ width: 800 }}>
          {activeStep === 0 && (
            <Step0
              key="0"
              name={formData.name}
              projectType={formData.projectType}
              onChangeName={this.onChangeName}
              onSaveName={this.onSaveName}
              availableTypes={projectTypes}
              onProjectType={this.onProjectType}
            />
          )}
          {activeStep === 1 && (
            <Step1
              key="1"
              formData={formData}
              projectStringProperties={formData.projectStringProperties}
              projectProperties={formData.projectProperties}
              onChangeMetaData={this.onChangeMetaData}
            />
          )}
          {activeStep === 2 && (
            <Step2
              key="2"
              project={this.props.project}
              formData={formData}
              onChangeFiles={this.onChangeFiles}
            />
          )}
          <MobileStepper
            variant="dots"
            steps={3}
            position="static"
            activeStep={activeStep}
            nextButton={
              <Button
                size="small"
                type="submit"
                disabled={
                  submitted ||
                  (this.state.activeStep === 2 &&
                    this.state.formData.files.length < 1)
                }
              >
                {/* Change Next Button depending on if you're editing a project
                 or creating a new one */}
                {this.props.project ? "Save" : "Next"}
                {!this.props.project && <KeyboardArrowRight />}
              </Button>
            }
            backButton={
              <Button
                size="small"
                onClick={this.handleBack}
                disabled={!this.props.project && activeStep === 0}
              >
                <KeyboardArrowLeft />
                {"Back"}
              </Button>
            }
          />
        </ValidatorForm>
      </Dialog>
    );
  }
}

CreateProjectDialog.propTypes = {
  project: PropTypes.object,
  onClose: PropTypes.func,
  selectedValue: PropTypes.string,
  newdefaultname: PropTypes.string,
  handle_active_tab_change: PropTypes.func,
  open: PropTypes.bool,
  history: PropTypes.object,
};

export default withRouter(CreateProjectDialog);
