import React from "react";
import PropTypes from "prop-types";
import withStyles from "@mui/styles/withStyles";
import Backend from "../../common/utils/Backend";

const styles = () => ({
  root: {
    width: "100%",
  },
  sceneTile: {
    display: "inline-block",
  },
});

class CasesViewer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      projects: null,
    };
    Backend.listProjects().then((data) => {
      this.setState({
        projects: data.projects,
      });
    });
  }

  render() {
    const { classes, selectedCase } = this.props;
    const { projects } = this.state;

    return (
      <div>
        {projects &&
          projects.length > selectedCase &&
          projects[selectedCase].files.map((file) => {
            return (
              <div key={file.id} className={classes.sceneTile}>
                <img
                  style={{
                    display: "block",
                    background: "black",
                    margin: "5px",
                    objectFit: "contain",
                  }}
                  width="200"
                  height="200"
                  src={Backend.renderThumbnail(file.id)}
                  alt=""
                />
              </div>
            );
          })}
      </div>
    );
  }
}

CasesViewer.propTypes = {
  classes: PropTypes.object.isRequired,
  selectedCase: PropTypes.number,
};

export default withStyles(styles)(CasesViewer);
