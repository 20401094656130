import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import XLSX from "xlsx";
import Backend from "../../common/utils/Backend";
import { Select, MenuItem } from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import DragHandleIcon from "@mui/icons-material/DragHandle";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

import ProjectsTimeLineChart from "./CreateTimeLineChartDialogComponents/ProjectsTimeLineChart";

import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    display: "grid",
    gridTemplateColumns: "250px 1fr",
  },
  projectItem: {
    userSelect: "none",
  },
  errorItem: {
    color: theme.palette.error.main,
  },
}));

const CreateTimeLineChartDialog = ({
  open,
  close,
  selectedProjectIds,
  projects,
}) => {
  const classes = useStyles();

  // Initialize state for projects
  const [selectedProjects, setSelectedProjects] = useState([]);
  // add state for selected projects, that did not load
  const [selectedProjectsFailed, setSelectedProjectsFailed] = useState([]);
  const [projectData, setProjectData] = useState({}); // [project.id]: { columnHeadlines: [], combinedResultRows: [] }
  const [targetColumnHeadlines, setTargetColumnHeadlines] = useState([]); // [columnHeadline]
  const [selectedTargetColumnHeadline, setSelectedTargetColumnHeadline] =
    useState(""); // columnHeadline

  // Fetch project data whenever selectedProjectIds change
  useEffect(() => {
    if (open) {
      const filteredProjects = projects.filter((project) =>
        selectedProjectIds.includes(project.id)
      );

      const isDifferent = (arr1, arr2) => {
        if (arr1.length !== arr2.length) {
          return true;
        }

        const sortedArr1 = [...arr1].sort();
        const sortedArr2 = [...arr2].sort();

        for (let i = 0; i < sortedArr1.length; i++) {
          if (sortedArr1[i] !== sortedArr2[i]) {
            return true;
          }
        }

        return false;
      };

      // only update if the selected projects are different
      if (
        isDifferent(
          selectedProjects.map((p) => p.id),
          selectedProjectIds
        )
      ) {
        setSelectedProjects(filteredProjects);
        let projectData = {};
        const failedProjects = [];
        let promises = []; // Array to hold all requests

        for (const project of filteredProjects) {
          let download_path = Backend.downloadReport(project.id);
          let url = window.location.origin + download_path;

          /* set up async GET request */
          let promise = new Promise((resolve) => {
            // Wrap each request in a Promise
            let req = new XMLHttpRequest();
            req.open("GET", url, true);
            req.responseType = "arraybuffer";

            req.onload = () => {
              let data = new Uint8Array(req.response);
              let workbook = XLSX.read(data, { type: "array" });
              const roiLayerInfoSheet = workbook.Sheets["Roi Layer Info"];

              // Convert the worksheet to JSON
              let sheetToJson = XLSX.utils.sheet_to_json(roiLayerInfoSheet, {
                header: 1,
              });

              // Load the first row into an array
              let columnHeadlines = sheetToJson[0];

              let combinedResultRows = [];
              let combindedResultsStartIndex = -1;
              for (let i = 1; i < sheetToJson.length; i++) {
                let resultRow = sheetToJson[i];
                if (resultRow[0] === "Combined Results") {
                  // add this row and all following rows to the combinedResultRows array
                  combindedResultsStartIndex = i;
                  break;
                }
              }
              if (combindedResultsStartIndex !== -1) {
                for (
                  let i = combindedResultsStartIndex;
                  i < sheetToJson.length;
                  i++
                ) {
                  let resultRow = sheetToJson[i];
                  combinedResultRows.push(resultRow);
                }
              }
              const projectSheetData = {
                columnHeadlines: columnHeadlines,
                combinedResultRows: combinedResultRows,
              };
              if (combinedResultRows.length > 0) {
                projectData[project.id] = projectSheetData;
              } else {
                failedProjects.push(projects.find((p) => p.id === project.id));
              }
              resolve(); // Resolve the promise when done
            };
            req.send();
          });

          promises.push(promise); // Add every promise to the array
        }

        Promise.all(promises).then(() => {
          if (Object.keys(projectData).length === 0) {
            // no project data was loaded
            setSelectedProjects([]);
            setSelectedProjectsFailed(filteredProjects);
            return;
          }
          // Wait for all promises to resolve
          // get the first project data object
          const firstProjectDataObject = Object.values(projectData)[0];

          // Use slice to remove the first 2 items and get rest of the items in columnHeadlines
          const updatedColumnHeadlines =
            firstProjectDataObject.columnHeadlines.slice(2);

          setTargetColumnHeadlines(updatedColumnHeadlines);
          setSelectedTargetColumnHeadline(updatedColumnHeadlines[0]);

          setProjectData(projectData);
          const validProjects = [];
          for (const projectId of Object.keys(projectData)) {
            const entryProject = projects.find((p) => p.id === projectId);
            validProjects.push(entryProject);
          }
          setSelectedProjects(validProjects);
          setSelectedProjectsFailed(failedProjects);
        });
      }
    }
  }, [open]);

  const onDragEnd = (result) => {
    // Dropped outside the list or in the same place
    if (
      !result.destination ||
      result.source.index === result.destination.index
    ) {
      return;
    }

    const newSelectedProjects = Array.from(selectedProjects);
    const [movedItem] = newSelectedProjects.splice(result.source.index, 1);
    newSelectedProjects.splice(result.destination.index, 0, movedItem);

    setSelectedProjects(newSelectedProjects);
  };

  return (
    <>
      <Dialog onClose={close} open={open} maxWidth="lg" scroll="paper">
        <DialogTitle>Timeline Chart</DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <div>
            {/* dropdown to select the column headline to use for the timeline chart */}

            <Select
              value={selectedTargetColumnHeadline}
              onChange={(e) => setSelectedTargetColumnHeadline(e.target.value)}
            >
              {targetColumnHeadlines.map((columnHeadline) => (
                <MenuItem key={columnHeadline} value={columnHeadline}>
                  {columnHeadline}
                </MenuItem>
              ))}
            </Select>

            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="projects">
                {(provided) => (
                  <List ref={provided.innerRef} {...provided.droppableProps}>
                    {selectedProjects.map((project, index) => (
                      <Draggable
                        key={project.id}
                        draggableId={project.id}
                        index={index}
                      >
                        {(provided) => (
                          <ListItem
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <ListItemIcon>
                              <DragHandleIcon />
                            </ListItemIcon>
                            <ListItemText
                              primary={
                                project.readableId + " : " + project.name
                              }
                            />
                          </ListItem>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </List>
                )}
              </Droppable>
            </DragDropContext>
            <div>
              {selectedProjectsFailed.length > 0 && (
                <div className={classes.errorItem}>
                  Project results could not be loaded properly and will not be
                  included in Chart:
                </div>
              )}

              <List>
                {selectedProjectsFailed.map((project) => (
                  <ListItem key={project.id}>
                    <ListItemIcon>
                      <ErrorOutlineIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary={project.readableId + " : " + project.name}
                    />
                  </ListItem>
                ))}
              </List>
            </div>
          </div>
          <div style={{ position: "relative" }}>
            <ProjectsTimeLineChart
              selectedProjects={selectedProjects}
              selectedTargetColumnHeadline={selectedTargetColumnHeadline}
              projectData={projectData}
            />
          </div>
        </DialogContent>

        <DialogActions>
          <Button onClick={close} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

CreateTimeLineChartDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  selectedProjectIds: PropTypes.arrayOf(PropTypes.string),
  projects: PropTypes.arrayOf(PropTypes.object),
};

export default CreateTimeLineChartDialog;
