import React, { Component } from "react";
import PropTypes from "prop-types";

import RBush from "rbush";
import { getRandomColor } from "../utils/RandomColorGenerator";
import {
  getParentIndexLayer,
  updateStructures,
} from "../utils/StructuresUtils";

const ProjectContext = React.createContext();

export const withProject = (Component) => {
  const WrappedComponent = (props) => (
    <ProjectContext.Consumer>
      {(context) => <Component {...props} projectContext={context} />}
    </ProjectContext.Consumer>
  );

  WrappedComponent.displayName = `withProject(${
    Component.displayName || Component.name || "Component"
  })`;

  return WrappedComponent;
};

// this is the main component, it has to be added at the root of the app.
// all components that use withProject(...) will have access to it via this.props.Project...
class ProjectProvider extends Component {
  constructor(props) {
    super(props);

    this._isMounted = false;
    this.state = {
      structures: [],
      roiLayers: {},
      commentLayers: {},
      selectedLayer: 0,
      ome: null,
      activeTab: 0,
      changingFile: false,
      user: "",
      isLoadingAnnotations: false,
      galleryImageSize: 300,
      annotationsReduced: false,
      totalRoiCount: -1,
      opacity: 1.2,
    };
    this.aiModelRepository = [];
    this.aiStateObject = {};
  }

  setMountedState = (stateObject, callback) => {
    if (this._isMounted) {
      this.setState(stateObject, callback);
    }
  };

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate = () => {
    if (this.persistentStorage && this.state.project) {
      const structuresStateList = this.state.structures.map((structure) => {
        return {
          isUnfolded: structure.isUnfolded,
          showSubtypes: structure.showSubtypes,
          visible: structure.visible,
          color: structure.color,
        };
      });
      this.persistentStorage.saveProjectTypeValue(
        this.state.project.type,
        "structuresStateList",
        structuresStateList
      );
    }
  };

  /**
   * Initializes the Project provider and passes a viewer component
   *
   * @param {object} viewer - Viewer Component
   */
  init = (viewer) => {
    this.viewer = viewer;
  };

  setChangingFile = (a) => {
    this.setMountedState({ changingFile: a });
  };

  setActiveTab = (tab) => {
    this.setMountedState({ activeTab: tab });
    this.activeTab = tab;
    this.forceUpdate();
  };

  getActiveTab = () => {
    return this.state.activeTab;
  };

  colorInUse = (color) => {
    for (let structure of this.state.structures) {
      if (color === structure.color) return true;
    }
    return false;
  };

  getUnusedColor = () => {
    let color = getRandomColor();
    while (this.colorInUse(color)) {
      color = getRandomColor();
    }
    return color;
  };

  /**
   * Returns Roi Region Object
   *
   * @param {int} structureId - id of the structure
   * @param {int} fileId - id of the file, if undefined the currently opened file will be used
   */
  getRegionById = (structureId, fileId) => {
    if (!fileId) fileId = this.state.fileId;
    return this.state.roiLayers[fileId].find((c) => c.id === structureId);
  };

  getNumberOfChilds = (parentStructure) => {
    const { structures } = this.state;

    return structures.filter(
      (element) => element.parentId === parentStructure.id
    ).length;
  };

  importStructures = (structures) => {
    const { roiLayers, fileId } = this.state;

    for (let structure of structures) {
      if (structure.tools.length === 0) {
        if (structure.parentId > 0) {
          let parentStructure = structures.find(
            (item) => item.id === structure.parentId
          );
          if (parentStructure) {
            structure.tools = parentStructure.tools;
          }
        }
      }
      const roiLayer = roiLayers[fileId].find((c) => c.id === structure.id);
      if (!roiLayer) {
        roiLayers[fileId].push({
          id: structure.id,
          layer: {
            regionRois: [],
            inverted: false,
          },
          tree: new RBush(),
        });
      }
    }

    this.setMountedState({ structures: structures, roiLayers });
  };

  /**
   * Adds a new Dynamic Structure to the Project Model
   *
   * @param {string} name - Display name of the new structure
   */
  addStructure = (name, grid = false) => {
    const { structures, roiLayers, viewerConfig, fileId } = this.state;

    // findHighest id number
    const newLayerId = structures.reduce((a, c) => Math.max(a, c.id), 0) + 1;

    let newStructure = {
      id: newLayerId,
      dynamic: true,
      //name: name,
      label: name,
      color: grid ? "#00000000" : this.getUnusedColor(),
      visible: true,
      allToolNames: structures[0].allToolNames,
      tools: viewerConfig.project.dynamicStructure.tools,
      classFrequencies: {
        class_0: 0,
        class_1: 0,
        class_2: 0,
        class_3: 0,
        class_4: 0,
        class_5: 0,
        class_6: 0,
      },
      avgClassFrequnecy: 0,
      isSubtype: false,
      isUnfolded: true,
      classificationSubtype: false,
      parentId: 0,
      showSubtypes: false,
      subtypeLevel: 0,
      defaultSelected: false,
    };
    newStructure = this.viewer.alterStructure(newStructure);

    let newRoilayer = {
      id: newLayerId,
      layer: {
        regionRois: [],
        inverted: false,
      },
      tree: new RBush(),
    };

    structures.push(newStructure);

    roiLayers[fileId].push(newRoilayer);

    this.setMountedState({ structures, roiLayers });
  };

  addSubStructure = (parent, name, fromHistoModule) => {
    const { structures, roiLayers, selectedLayer, fileId } = this.state;

    // findHighest id number
    const newLayerId = structures.reduce((a, c) => Math.max(a, c.id), 0) + 1;

    let subStructure = {
      id: newLayerId,
      dynamic: true,
      parentId: parent.id,
      label: name ? name : parent.label,
      color: this.getUnusedColor(),
      visible: true,
      allToolNames: parent.allToolNames,
      tools: parent.tools, // what tools should be used?
      classFrequencies: {
        class_0: 0,
        class_1: 0,
        class_2: 0,
        class_3: 0,
        class_4: 0,
        class_5: 0,
        class_6: 0,
      },
      avgClassFrequnecy: 0,
      showSubtypes: false,
      isSubtype: true,
      parentColor: parent.color,
      subtypeColor: this.getUnusedColor(),
      isUnfolded: true,
      subtypeLevel: parent.subtypeLevel + 1,
      hasChild: false,
      classificationSubtype: false,
      defaultSelected: false,
    };
    // add substructure as last child (for now just in histo modules / for other modules check if this works too)
    let numberChilds = 0;
    if (fromHistoModule) {
      numberChilds = this.getNumberOfChilds(structures[selectedLayer]);
    }

    structures.splice(selectedLayer + 1 + numberChilds, 0, subStructure);
    const roiIndex = roiLayers[fileId].findIndex(
      (c) => c.id === structures[selectedLayer].id
    );

    roiLayers[fileId].splice(roiIndex + 1, 0, {
      id: newLayerId,
      layer: {
        regionRois: [],
        inverted: false,
      },
      tree: new RBush(),
    });
    parent.addSubtypeText = "";
    parent.hasChild = true;

    this.setMountedState({ structures, roiLayers });
  };

  addSubType = (parent, name, fromHistoModule) => {
    const { structures, roiLayers, selectedLayer, fileId } = this.state;

    // findHighest id number
    const newLayerId = structures.reduce((a, c) => Math.max(a, c.id), 0) + 1;

    let subStructure = {
      id: newLayerId,
      dynamic: true,
      parentId: parent.id,
      label: name ? name : parent.label,
      color: this.getUnusedColor(),
      visible: true,
      allToolNames: parent.allToolNames,
      tools: parent.tools, // what tools should be used?
      classFrequencies: {
        class_0: 0,
        class_1: 0,
        class_2: 0,
        class_3: 0,
        class_4: 0,
        class_5: 0,
        class_6: 0,
      },
      avgClassFrequnecy: 0,
      showSubtypes: false,
      isSubtype: true,
      isUnfolded: fromHistoModule ? false : true,
      subtypeLevel: parent.subtypeLevel + 1,
      hasChild: false,
      classificationSubtype: true,
      defaultSelected: false,
    };
    // add substructure as last child (for now just in histo modules / for other modules check if this works too)
    let numberChilds = 0;
    if (fromHistoModule) {
      numberChilds = this.getNumberOfChilds(structures[selectedLayer]);
      // for point counting module only 11 subtypes are possible because of template
      if (numberChilds >= 10) {
        window.showWarningSnackbar("Reached maximum amount of subtypes.");
        return;
      }
    }

    structures.splice(selectedLayer + 1 + numberChilds, 0, subStructure);
    const roiIndex = roiLayers[fileId].findIndex(
      (c) => c.id === structures[selectedLayer].id
    );

    roiLayers[fileId].splice(roiIndex + 1 + numberChilds, 0, {
      id: newLayerId,
      layer: {
        regionRois: [],
        inverted: false,
      },
      tree: new RBush(),
    });
    parent.addSubtypeText = "";
    parent.hasChild = true;

    this.setMountedState({ structures, roiLayers });
  };

  findChilds = (subType) => {
    const { structures } = this.state;

    return structures.filter(
      (element) =>
        element.subtypeLevel === subType.subtypeLevel + 1 &&
        element.parentId === subType.id
    );
  };

  /**
   * Removes a structure and corresponding regions from project model.
   *
   * @param {object} structure - Structure to remove.
   * @returns {Int} The id of the next layer to be selected.
   */
  deleteStructure = (structure) => {
    const { selectedLayer, fileId } = this.state;
    let { structures, roiLayers } = this.state;

    // Recursively delete all children.
    if (structure.hasChild) {
      let childs = this.findChilds(structure);
      for (let i = 0; i < childs.length; i++) {
        this.deleteStructure(childs[i]);
      }
    }

    let toplevelParentId = null;
    // In case of subtypes, find the next (sub-)structure
    if (structure.classificationSubtype) {
      toplevelParentId =
        structures[getParentIndexLayer(structure, structures)].id;
    }
    // Otherwise, get the direct parent
    else {
      toplevelParentId = structure.parentId;
    }

    // Delete from structure
    const structureIndex = structures.findIndex((c) => c.id === structure.id);
    structures.splice(structureIndex, 1);

    // Delete from ROIs.
    const roiIndex = roiLayers[fileId].findIndex((c) => c.id === structure.id);
    roiLayers[fileId].splice(roiIndex, 1);

    // In case of subtype, delete ROIs from containing layer.
    if (structure.isSubtype) {
      // Get structure where ROIs are stored
      let roiIdx = roiLayers[fileId].findIndex(
        (struct) => struct.id === toplevelParentId
      );

      // Only keep ROIs not from the deleted subtype
      let subtypesRemoved = roiLayers[fileId][roiIdx].tree.data.children.filter(
        (RegionRoi) =>
          !RegionRoi.roi.isSubtype &&
          RegionRoi.roi.subtypeName !== structure.label
      );

      // Overwrite previous ROIs
      roiLayers[fileId][roiIdx].tree.data.children = subtypesRemoved;
    }

    // Update children status of parent.
    let parentStruct = structures.find(
      (struct) => struct.id === structure.parentId
    );
    // Only update if there is something to update
    if (parentStruct) {
      structures = updateStructures(parentStruct, structures);
    }

    // if last layer gets deleted make selectedLayer 0
    let newSelectedLayer =
      selectedLayer >= structures.length ? 0 : selectedLayer;

    this.setMountedState({
      structures,
      roiLayers,
      selectedLayer: newSelectedLayer,
    });

    return newSelectedLayer;
  };

  /**
   * Duplicates a structure and corresponding parameters from project model.
   *
   * @param {object} structure - Structure to remove
   */
  duplicateStructure = (structure) => {
    const { structures, roiLayers, fileId } = this.state;

    // findHighest id number
    const newLayerId =
      structures.reduce((a, c) => (a ? Math.max(a, c.id) : c.id), 0) + 1;

    // clone structure
    const pureLabel = structure.label.split(" - copy")[0];
    const copiesCount = structures.reduce(
      (a, c) => (c.label.startsWith(pureLabel + " - copy") ? a + 1 : a),
      0
    );
    let newStructure = Object.assign(JSON.parse(JSON.stringify(structure)), {
      id: newLayerId,
      label:
        copiesCount > 0
          ? pureLabel + " - copy(" + (copiesCount + 1) + ")"
          : pureLabel + " - copy",
    });

    let newRoilayer = {
      id: newLayerId,
      layer: {
        regionRois: [],
        inverted: false,
      },
      tree: new RBush(),
    };

    structures.push(newStructure);
    roiLayers[fileId].push(newRoilayer);

    this.duplicateChildren(structure, newStructure);

    let newSelectedLayer = structures.findIndex((c) => c.id === newLayerId);
    this.setMountedState({
      structures,
      roiLayers,
      selectedLayer: newSelectedLayer,
    });

    return newSelectedLayer;
  };

  findChildren = (structure) => {
    const { structures } = this.state;

    return structures.filter(
      (element) =>
        element.subtypeLevel === structure.subtypeLevel + 1 &&
        element.parentId === structure.id
    );
  };

  duplicateChildren = (oldParent, newParent) => {
    const { structures, roiLayers, fileId } = this.state;

    for (let structure of this.findChildren(oldParent)) {
      // findHighest id number
      const newId =
        structures.reduce((a, c) => (a ? Math.max(a, c.id) : c.id)) + 1;

      // clone structure
      let newStructure = Object.assign(JSON.parse(JSON.stringify(structure)), {
        id: newId,
        parentId: newParent.id,
      });

      let newRoilayer = {
        id: newId,
        layer: {
          regionRois: [],
          inverted: false,
        },
        tree: new RBush(),
      };

      structures.push(newStructure);
      roiLayers[fileId].push(newRoilayer);

      this.duplicateChildren(structure, newStructure);
    }
  };

  /**
   * Checks if a structure can be moved in a direction.
   *
   * @param {Object} selectedStructure - Structure to move
   * @param {int} direction - 1 is up -1 is down
   * @returns {bool} Move in this direction possible
   */
  canMoveStructure = (selectedStructure, direction) => {
    const { structures } = this.state;
    if (!selectedStructure.dynamic) return false;

    let structuresSameLevel = {
      structure: [],
      index: [],
    };

    // get structures in same level of same parent
    structuresSameLevel.structure = structures.filter(
      (element) =>
        element.parentId === selectedStructure.parentId &&
        element.subtypeLevel === selectedStructure.subtypeLevel
    );

    // if parent is selected (e.g. Base Roi)
    if (!selectedStructure.isSubtype) {
      structuresSameLevel.structure = structures.filter(
        (element) => element.subtypeLevel === selectedStructure.subtypeLevel
      );
    }
    // get indices in all structures
    structuresSameLevel.structure.forEach((element) =>
      structuresSameLevel.index.push(
        structures.findIndex((structure) => structure === element)
      )
    );

    // index of selected layer of structures in same level
    let index = structuresSameLevel.structure.findIndex(
      (structure) => structure === selectedStructure
    );

    if (direction > 0) {
      // if first object --> move up not possible.
      // Also, moving above fixed, non-dynamic structures is forbidden.
      if (index === 0 || !structuresSameLevel.structure[index - 1].dynamic) {
        return false;
      } else {
        return true;
      }
    } else {
      // if last object --> move down not possible
      if (index === structuresSameLevel.structure.length - 1) {
        return false;
      } else {
        return true;
      }
    }
  };

  /**
   * Moves a structure up or down in structure list
   *
   * @param {Object} selectedStructure - Structure to move
   * @param {int} direction - 1 is up -1 is down
   */
  moveStructure = (selectedStructure, direction) => {
    const { structures, roiLayers } = this.state;

    let structuresSameLevel = {
      structure: [],
      index: [],
    };

    // get structures in same level of same parent
    structuresSameLevel.structure = structures.filter(
      (element) =>
        element.parentId === selectedStructure.parentId &&
        element.subtypeLevel === selectedStructure.subtypeLevel
    );

    // if parent is selected (e.g. Base Roi)
    if (!selectedStructure.isSubtype) {
      structuresSameLevel.structure = structures.filter(
        (element) => element.subtypeLevel === selectedStructure.subtypeLevel
      );
    }
    // get indices in all structures
    structuresSameLevel.structure.forEach((element) =>
      structuresSameLevel.index.push(
        structures.findIndex((structure) => structure === element)
      )
    );

    // index of selected layer of structures in same level
    let index = structuresSameLevel.structure.findIndex(
      (structure) => structure === selectedStructure
    );

    if (direction > 0) {
      // if first object --> move up not possible.
      // Also, moving above fixed, non-dynamic structures is forbidden.
      if (index === 0 || !structuresSameLevel.structure[index - 1].dynamic) {
        window.showWarningSnackbar("Moving structure up not possible!");
        return;
      }
      // swap two neighbor structures with subtypes
      // get all elements of selected structure
      let strt = structuresSameLevel.index[index];
      let ed = structuresSameLevel.index[index + 1];
      // if structure to move up is last structure
      if (!ed) {
        // get index first structure in same level
        let idxFirstSubtype = structures.findIndex(
          (structure) => structure === structuresSameLevel.structure[0]
        );
        for (let i = idxFirstSubtype; i < structures.length; i++) {
          if (structures[i].subtypeLevel < selectedStructure.subtypeLevel) {
            ed = i;
            break;
          }
        }
      }
      // Get all children of the selected structure.
      let allElements_1 = structures.slice(strt, ed);

      // delete elements to swap from structures
      structures.splice(structuresSameLevel.index[index], allElements_1.length);

      // put elements to right index in structures
      let start = structures.findIndex(
        (structure) => structure === structuresSameLevel.structure[index - 1]
      );
      for (let i = allElements_1.length - 1; i >= 0; i--) {
        structures.splice(start, 0, allElements_1[i]);
      }
    } else {
      // if last object --> move down not possible
      if (index === structuresSameLevel.structure.length - 1) {
        window.showWarningSnackbar("Moving structure down not possible!");
        return;
      }

      // swap two neighbor structures with subtypes
      // get all elements of selected structure
      let strt = structuresSameLevel.index[index];
      let ed = structuresSameLevel.index[index + 1];
      let allElements_1 = structures.slice(strt, ed);

      // delete elements of first structure to swap
      structures.splice(structuresSameLevel.index[index], allElements_1.length);

      // put elements to right index in structures
      let start = structures.findIndex(
        (structure) => structure === structuresSameLevel.structure[index + 2]
      );

      // if structure to swap is last subtype
      let lastStructure = false;
      if (start === -1) {
        // get index of structure to swap
        let idxSwapStructure = structures.findIndex(
          (structure) => structure === structuresSameLevel.structure[index + 1]
        );
        // if it is the last structure of all structures
        // search next parent structure
        let otherParent = false;
        for (let i = idxSwapStructure; i < structures.length; i++) {
          if (structures[i].subtypeLevel < selectedStructure.subtypeLevel) {
            otherParent = true;
          }
        }
        if (!otherParent) {
          lastStructure = true;
        } else {
          // search next parent structure
          for (let i = idxSwapStructure; i < structures.length; i++) {
            if (structures[i].subtypeLevel < selectedStructure.subtypeLevel) {
              start = i;
              break;
            }
          }
        }
      }

      if (!lastStructure) {
        // put all elements to index after structure to swap
        for (let i = allElements_1.length - 1; i >= 0; i--) {
          structures.splice(start, 0, allElements_1[i]);
        }
      } else {
        // if last structure push elements to the end
        for (let i = 0; i < allElements_1.length; i++) {
          structures.push(allElements_1[i]);
        }
      }
    }

    // Match roiLayer structure to new structure structure.
    // Sorts the roilayers for each scene in project by having their ID-order
    // match the order set by the structures.
    for (let sceneGUID in roiLayers) {
      roiLayers[sceneGUID].sort((roi_layer_a, roi_layer_b) => {
        // Location of the first roi-Layer Id in strcutures
        let index_of_roi_layer_a_in_structures = structures.findIndex(
          (element) => {
            return element.id === roi_layer_a.id;
          }
        );

        // Location of the second roi-Layer Id in strcutures
        let index_of_roi_layer_b_in_structures = structures.findIndex(
          (element) => {
            return element.id === roi_layer_b.id;
          }
        );

        // Check if the first index comes before the second.
        // Else, swap per sort algorithm.
        return (
          index_of_roi_layer_a_in_structures -
          index_of_roi_layer_b_in_structures
        );
      });
    }

    let newSelectedLayer = structures.findIndex((c) => c === selectedStructure);
    this.setMountedState({ structures, selectedLayer: newSelectedLayer });
    return newSelectedLayer;
  };

  getProjectStringInfos = () => {
    const project = this.state.project;
    const file = project.files.find((file) => file.id === this.state.fileId);
    const fileName = file.fileName.split(".").slice(0, -1).join(".");
    let today = new Date();
    let dd = String(today.getDate()).padStart(2, "0");
    let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    let yyyy = today.getFullYear();
    today = yyyy + "-" + mm + "-" + dd;

    // projekt_methode_filename_SceneNr_001.png
    return {
      name: project.name,
      type: project.type,
      fileName: fileName,
      date: today,
    };
  };

  setStateNow = (newState) => {
    Object.assign(this.state, newState);
    this.forceUpdate();
  };

  setAiModelRepository = (aiModelRepository) => {
    this.aiModelRepository = aiModelRepository;
  };

  setAiStateObject = (aiStateObject) => {
    this.aiStateObject = aiStateObject;
  };

  setPersistentStorage = (persistentStorage) => {
    this.persistentStorage = persistentStorage;
  };

  render() {
    return (
      <ProjectContext.Provider
        value={{
          setPersistentStorage: this.setPersistentStorage,
          init: this.init,
          setState: (e, callback) => this.setMountedState(e, callback),
          forceUpdate: () => this.forceUpdate(),
          getRegionById: this.getRegionById,
          setChangingFile: this.setChangingFile,
          setActiveTab: this.setActiveTab,
          getActiveTab: this.getActiveTab,
          /* Handle Structures */
          importStructures: this.importStructures,
          addStructure: this.addStructure,
          addSubStructure: this.addSubStructure,
          addSubType: this.addSubType,
          deleteStructure: this.deleteStructure,
          moveStructure: this.moveStructure,
          canMoveStructure: this.canMoveStructure,
          duplicateStructure: this.duplicateStructure,
          setStateNow: this.setStateNow,
          getProjectStringInfos: this.getProjectStringInfos,
          setAiModelRepository: this.setAiModelRepository,
          setAiStateObject: this.setAiStateObject,
          /* State */
          ...this.state,
          fileRoiLayers:
            this.state.roiLayers && this.state.roiLayers[this.state.fileId],
        }}
      >
        {this.props.children}
      </ProjectContext.Provider>
    );
  }
}

ProjectProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export default ProjectProvider;
