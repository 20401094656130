import React, { Component } from "react";
import PropTypes from "prop-types";

import withStyles from "@mui/styles/withStyles";
import { v4 as uuidv4 } from "uuid";

import { getParentIndexLayer } from "../../../viewer/utils/StructuresUtils";

import {
  DialogContent,
  DialogContentText,
  MenuItem,
  TextField,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  FormControlLabel,
  Grid,
  Checkbox,
  ListItem,
  Button,
  Select,
  ListItemText,
  InputLabel,
  Typography,
} from "@mui/material";

const styles = () => ({
  dialogContent: {
    paddingTop: 0,
    maxWidth: 900,
    overflow: "hidden",
  },
  textInputField: {
    marginRight: 10,
  },
  dropDownLabel: {
    top: -7,
    left: 15,
  },
});

class TrainModelStep4 extends Component {
  constructor(props) {
    super(props);

    let maxLevel = props.ome.maxLevel;
    let encoders = [
      {
        name: "ResNest",
        types: [
          "resnest14d",
          "resnest26d",
          "resnest50d",
          "resnest50d_1s4x24d",
          "resnest50d_4s2x40d",
          "resnest101e",
          "resnest200e",
          "resnest269e",
        ],
        unfolded: [false, false, false, false],
      },
      {
        name: "ResNet",
        types: [
          "resnet18",
          "resnet26",
          "resnet34",
          "resnet50",
          "resnet101",
          "resnet152",
          "resnet200",
        ],
        unfolded: [false, false, false, false],
      },
      {
        name: "ResNext",
        types: [
          "resnext50d_32x4d",
          "resnext101_32x4d",
          "resnext101_32x8d",
          "resnest50d_1s4x24d",
          "resnest50d_4s2x40d",
          "resnext101_64x4d",
        ],
        unfolded: [false, false, false, false],
      },
      {
        name: "EfficientNet",
        types: [
          "efficientnet_b0",
          "efficientnet_b1",
          "efficientnet_b2",
          "efficientnet_b3",
          "efficientnet_b4",
          "efficientnet_b5",
          "efficientnet_b6",
          "efficientnet_b7",
          "efficientnet_b8",
        ],
        unfolded: [false, false, false, false],
      },
    ];
    this.decoderArchitectures = [
      {
        name: "unet",
        label: "Unet",
      },
      {
        name: "unet_pp",
        label: "Unet++",
      },
      {
        name: "deepLabV3_p",
        label: "DeepLabV3+",
      },
    ];
    let odModelArchitectures = [
      {
        model: "faster_rcnn",
        config: "r50_fpn_1x_coco",
        label: "Faster R-CNN",
        url: "https://download.openmmlab.com/mmdetection/v2.0/faster_rcnn/faster_rcnn_r50_fpn_2x_coco/faster_rcnn_r50_fpn_2x_coco_bbox_mAP-0.384_20200504_210434-a5d8aa15.pth",
      },
      {
        model: "cascade_rcnn",
        config: "r50_fpn_1x_coco",
        label: "Cascade R-CNN",
        url: "https://download.openmmlab.com/mmdetection/v2.0/cascade_rcnn/cascade_mask_rcnn_r50_fpn_1x_coco/cascade_mask_rcnn_r50_fpn_1x_coco_20200203-9d4dcb24.pth",
      },
    ];
    let instanceSegModelArchitectures = [
      { model: "mask_rcnn_vdl", config: "", label: "Mask R-CNN VDL" },
      {
        model: "mask_rcnn",
        config: "r50_fpn_1x_coco",
        label: "Mask R-CNN",
        url: "https://download.openmmlab.com/mmdetection/v2.0/mask_rcnn/mask_rcnn_r50_fpn_mstrain-poly_3x_coco/mask_rcnn_r50_fpn_mstrain-poly_3x_coco_20210524_201154-21b550bb.pth",
      },
      {
        model: "swin",
        config: "mask_rcnn_swin-t-p4-w7_fpn_ms-crop-3x_coco",
        label: "VisionTransformer",
        url: "https://download.openmmlab.com/mmdetection/v2.0/swin/mask_rcnn_swin-t-p4-w7_fpn_ms-crop-3x_coco/mask_rcnn_swin-t-p4-w7_fpn_ms-crop-3x_coco_20210906_131725-bacf6f7b.pth",
      },
    ];

    let docderArchitectursMap = {
      Unet: "unet",
      UnetPlusPlus: "unet_pp",
      DeepLabV3Plus: "deepLabV3_p",
    };
    let selectedLevel = maxLevel;
    let selectedEncoderTypeIdx = 0;
    let selectedDecoder = this.decoderArchitectures[0].name;
    let selectedEncoderArchitecture = encoders[0].types[1];
    let selectedOdModelArchitecture = odModelArchitectures[0].label;
    let selectedInstanceSegModelArchitecture =
      instanceSegModelArchitectures[0].label;

    if (props.selectedModel.versions) {
      try {
        let lastVersion =
          props.selectedModel.versions[props.selectedModel.versions.length - 1];

        if ("calculate_weight_map" in lastVersion) {
          if (
            this.props.formData.advancedSettings.calculateWeightMap !==
            lastVersion.calculate_weight_map
          ) {
            this.props.updateFormDataAdvSettings(
              "calculateWeightMap",
              lastVersion.calculate_weight_map
            );
          }
        }

        if (lastVersion && typeof lastVersion.pyramid_level !== "undefined")
          selectedLevel = lastVersion.pyramid_level;
        if (selectedLevel === -1) selectedLevel = maxLevel;
        this.props.updateFormDataAdvSettings("level", selectedLevel);

        if (lastVersion && typeof lastVersion.image_size !== "undefined") {
          this.props.updateFormDataAdvSettings(
            "tileSize",
            lastVersion.image_size
          );
          this.props.updateFormDataAdvSettings(
            "overlap",
            parseInt(lastVersion.image_size / 16)
          );
        }

        if (lastVersion.model !== "" && lastVersion.model !== "yolov5") {
          if (lastVersion.modeltype == "segmentation") {
            let modelData = JSON.parse(
              lastVersion.model.replaceAll("'", '"')
            )[0];
            selectedDecoder = docderArchitectursMap[modelData.decoder];

            let encoderTypeFound = false;
            for (let i = 0; i < encoders.length; i++) {
              let encoder = encoders[i];
              for (let encoderType of encoder.types) {
                if (modelData.backbone.includes(encoderType)) {
                  selectedEncoderTypeIdx = i;
                  selectedEncoderArchitecture = encoderType;
                  encoderTypeFound = true;
                  break;
                }
              }
              if (encoderTypeFound) break;
            }
            this.updateModelInFormData(
              selectedEncoderArchitecture,
              selectedDecoder
            );
          } else if (lastVersion.modeltype == "object detection") {
            selectedOdModelArchitecture = lastVersion.model;
            let selArchitecture = odModelArchitectures.filter(
              (element) => element.label == selectedOdModelArchitecture
            )[0];
            if (selArchitecture) {
              this.props.updateFormDataAdvSettings(
                "comDLArchitecture",
                selArchitecture
              );
            }
          } else {
            selectedInstanceSegModelArchitecture = lastVersion.model;
            let selArchitecture = instanceSegModelArchitectures.filter(
              (element) => element.label == selectedInstanceSegModelArchitecture
            )[0];
            if (selArchitecture) {
              this.props.updateFormDataAdvSettings(
                "comDLArchitecture",
                selArchitecture
              );
            }
          }
        }
        if (lastVersion.dataset_approach) {
          this.props.updateFormDataAdvSettings(
            "datasetApproach",
            lastVersion.dataset_approach
          );
        }
      } catch (e) {
        console.log("pyramid level not found in model, selecting max level", e);
      }
    }

    this.state = {
      settingsType: "dafault",
      selectedDecoder: selectedDecoder,
      selectedEncoderTypeIdx: selectedEncoderTypeIdx,
      selectedEncoderArchitecture: selectedEncoderArchitecture,
      encoders: encoders,
      odModelArchitectures: odModelArchitectures,
      instanceSegModelArchitectures: instanceSegModelArchitectures,
      selectedOdModelArchitecture: selectedOdModelArchitecture,
      selectedInstanceSegModelArchitecture:
        selectedInstanceSegModelArchitecture,
      isBrightfield:
        props.ome &&
        props.ome.channels.length === 1 &&
        props.ome.channels[0].type === "brightfield",
      allSettingsEnabled: false,
    };

    this.levelDataArray = [];

    //fill levelDataArray for drop down
    if (maxLevel > 0) {
      for (let i = maxLevel; i >= 0; i--) {
        let zoomFactor = 2 ** (maxLevel - i);
        let levelData = {
          level: i,
          description: "1 : " + zoomFactor,
        };
        if (i === 0) {
          levelData.description += " (Thumbnail)";
        }
        this.levelDataArray.push(levelData);
      }
    }
  }

  componentDidMount() {
    const { formData, ome, selectedModel } = this.props;

    // use saved settings of model if continue training
    if (selectedModel.name !== "newModel") {
      return;
    }

    let IMAGESIZEOPTIONS = [512, 256, 128];
    let MAXIMAGESIZE = 512;
    // let MINIMAGESIZE = 128;
    let structures = this.props.structures;
    let roiLayers = this.props.roiLayers;
    let selStructures = this.props.formData.selStructures;
    let largestRoiPx = 0;
    let totalRoisArea = 0;
    let numberRois = 0;
    for (let i = 0; i < selStructures.length; i++) {
      let layerIdx = getParentIndexLayer(selStructures[i], structures);
      for (let j = 0; j < roiLayers[layerIdx].layer.regionRois.length; j++) {
        let roi = roiLayers[layerIdx].layer.regionRois[j];
        let height = roi.bounds.bottom - roi.bounds.bottom;
        let width = roi.bounds.right - roi.bounds.left;
        let max = Math.max(height, width);
        largestRoiPx = max > largestRoiPx ? max : largestRoiPx;
        totalRoisArea += roi.area;
        numberRois += 1;
      }
    }

    let avgArea = totalRoisArea / numberRois;

    // largestRoiPx defines the size of the largest roi in the lowest pyramid level
    let pyramidLevel = this.props.ome.maxLevel;
    let imageSize = 512;
    if (formData.fullScene) {
      // if module where whole files get classified
      imageSize = 512;
      pyramidLevel = ome.maxLevel;
    } else if (largestRoiPx < MAXIMAGESIZE) {
      // if smaller than min size in lowest pyramid level
      pyramidLevel = this.props.ome.maxLevel;
      let objects150ImageSize = Math.sqrt(150 * avgArea);
      imageSize = IMAGESIZEOPTIONS.find((element) => {
        return element < objects150ImageSize;
      });
      imageSize = imageSize ? imageSize : 128;
    } else {
      // if larger than max size in lowest pyramid level --> get pyramid level where largest object is smaller tha max size
      // for each level higher than the base pyramid level, we halve the object size
      let lvDistance = Math.ceil(
        Math.log(largestRoiPx / MAXIMAGESIZE) / Math.log(2)
      );
      if (formData["modelType"] == "segmentation") {
        // go maximum 2 levels above lowest pyramid level
        lvDistance = Math.min(2, lvDistance);
      }
      pyramidLevel = this.props.ome.maxLevel - lvDistance;
      pyramidLevel = Math.max(0, pyramidLevel); // no negative pyramid levels are possible
      imageSize = 512;
      // size of largest object in new pyramid level
      largestRoiPx = largestRoiPx / Math.pow(2, Math.ceil(lvDistance));
    }

    // update properties with calculated values
    this.props.updateFormDataAdvSettings("level", pyramidLevel);
    this.props.updateFormDataAdvSettings("tileSize", imageSize);
    this.props.updateFormDataAdvSettings("overlap", parseInt(imageSize / 16));
  }

  handleChangeEncoder = (idx, encoder, checked, architecture) => {
    const { formData } = this.props;

    let encoders = formData["advancedSettings"]["backbones"][architecture];

    if (checked) {
      if (!encoders.includes(encoder)) {
        encoders.push(encoder);
      }
    } else {
      let index = encoders.indexOf(encoder);
      if (index > -1) {
        encoders.splice(index, 1);
      }
    }

    this.props.updateFormDataAdvSettingsEncoders(
      "backbones",
      encoders,
      architecture
    );
  };

  renderEncoder = (encoder, architecture) => {
    const { formData } = this.props;

    return (
      <React.Fragment>
        {encoder.types.map((encoder, idx) => (
          <ListItem key={uuidv4()}>
            <FormControlLabel
              label={encoder}
              control={
                <Checkbox
                  checked={formData["advancedSettings"]["backbones"][
                    architecture
                  ].includes(encoder)}
                  onChange={(e) =>
                    this.handleChangeEncoder(
                      idx,
                      encoder,
                      e.currentTarget.checked,
                      architecture
                    )
                  }
                />
              }
            />
          </ListItem>
        ))}
      </React.Fragment>
    );
  };

  getOptimizerIndex = (optimizers) => {
    let index = optimizers.indexOf(
      this.props.formData["advancedSettings"]["optimizer"]
    );
    if (index > -1) {
      return index;
    } else {
      return 0;
    }
  };

  updateModelInFormData = (encoderArchitecture, decoderArchitecture) => {
    for (let item of this.decoderArchitectures) {
      this.props.updateFormDataAdvSettingsEncoders(
        "backbones",
        item.name === decoderArchitecture ? [encoderArchitecture] : [],
        item.name
      );
    }
  };

  updateComDLModelInFormData = (encoderArchitecture, decoderArchitecture) => {
    for (let item of this.decoderArchitectures) {
      this.props.updateFormDataAdvSettingsEncoders(
        "backbones",
        item.name === decoderArchitecture ? [encoderArchitecture] : [],
        item.name
      );
    }
  };

  setObjectBasedStructure = () => {
    const { formData, structures } = this.props;

    if (formData.selStructures.length == 0) {
      return;
    }

    // get parent of first selected structure
    let structureAnnotationIndex = getParentIndexLayer(
      formData.selStructures[0],
      structures
    );
    let parentStructure = structures.find(
      (s) => s.id === structures[structureAnnotationIndex].parentId
    );
    let parentAnnotationIndex = getParentIndexLayer(
      parentStructure,
      structures
    );

    this.props.updateFormDataAdvSettings(
      "objectBasedBaseStructure",
      structures[parentAnnotationIndex]
    );
  };

  renderModelSettings = () => {
    const optimizers = ["Adam", "AdamW", "SGD"];
    const {
      encoders,
      selectedDecoder,
      selectedEncoderTypeIdx,
      selectedEncoderArchitecture,
      odModelArchitectures,
      instanceSegModelArchitectures,
      selectedOdModelArchitecture,
      selectedInstanceSegModelArchitecture,
      allStructuresEnabled,
    } = this.state;
    const { formData, histogramConfig } = this.props;

    return (
      <div style={{ paddingTop: "10px" }}>
        <Grid container>
          <Grid item xs={10}>
            <DialogContentText style={{ marginBottom: "0px" }}>
              Model architecture:
            </DialogContentText>
          </Grid>
          <Grid item xs={2}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={allStructuresEnabled}
                  onChange={(e) =>
                    this.setState({ allStructuresEnabled: e.target.checked })
                  }
                />
              }
              label={
                <Typography variant="caption">Enable all settings</Typography>
              }
            />
          </Grid>
        </Grid>

        {formData["modelType"] == "segmentation" && (
          <React.Fragment>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <FormControl fullWidth>
                  <TextField
                    disabled={this.props.selectedModel.name !== "newModel"}
                    select
                    label="Decoder / Architecture"
                    value={selectedDecoder}
                    onChange={(e) => {
                      this.setState({ selectedDecoder: e.target.value });
                      this.updateModelInFormData(
                        selectedEncoderArchitecture,
                        e.target.value
                      );
                    }}
                  >
                    {this.decoderArchitectures.map((decoderArchitecture) => (
                      <MenuItem
                        key={decoderArchitecture.name}
                        value={decoderArchitecture.name}
                      >
                        {decoderArchitecture.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </FormControl>
              </Grid>
            </Grid>
            <br />
          </React.Fragment>
        )}

        {(formData["modelType"] == "segmentation" ||
          formData["modelType"] == "classification") && (
          <React.Fragment>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <FormControl fullWidth>
                  <TextField
                    disabled={this.props.selectedModel.name !== "newModel"}
                    select
                    label="Encoder Type"
                    value={selectedEncoderTypeIdx}
                    onChange={(e) => {
                      this.setState({
                        selectedEncoderTypeIdx: e.target.value,
                        selectedEncoderArchitecture:
                          encoders[e.target.value].types[0],
                      });
                      this.updateModelInFormData(
                        encoders[e.target.value].types[0],
                        selectedDecoder
                      );
                    }}
                  >
                    {this.state.encoders.map((encoder, idx) => (
                      <MenuItem key={idx} value={idx}>
                        {encoder.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl fullWidth>
                  <TextField
                    disabled={this.props.selectedModel.name !== "newModel"}
                    select
                    label="Encoder Architecture"
                    value={selectedEncoderArchitecture}
                    onChange={(e) => {
                      this.setState({
                        selectedEncoderArchitecture: e.target.value,
                      });
                      this.updateModelInFormData(
                        e.target.value,
                        selectedDecoder
                      );
                    }}
                  >
                    {encoders[selectedEncoderTypeIdx].types.map(
                      (encoderType) => (
                        <MenuItem key={encoderType} value={encoderType}>
                          {encoderType}
                        </MenuItem>
                      )
                    )}
                  </TextField>
                </FormControl>
              </Grid>
            </Grid>
          </React.Fragment>
        )}

        {formData["modelType"] == "object detection" && (
          <React.Fragment>
            <TextField
              style={{ marginTop: "10px", marginBottom: "10px" }}
              disabled={this.props.selectedModel.name !== "newModel"}
              select
              label="Architecture"
              value={selectedOdModelArchitecture}
              onChange={(e) => {
                this.setState({
                  selectedOdModelArchitecture: e.target.value,
                });
                this.props.updateFormDataAdvSettings(
                  "comDLArchitecture",
                  odModelArchitectures.filter(
                    (element) => element.label == e.target.value
                  )[0]
                );
              }}
            >
              {odModelArchitectures.map((architecture) => (
                <MenuItem key={architecture.label} value={architecture.label}>
                  {architecture.label}
                </MenuItem>
              ))}
            </TextField>
          </React.Fragment>
        )}

        {formData["modelType"] == "instance segmentation" && (
          <React.Fragment>
            <TextField
              style={{ marginTop: "10px", marginBottom: "10px" }}
              disabled={this.props.selectedModel.name !== "newModel"}
              select
              label="Architecture"
              value={selectedInstanceSegModelArchitecture}
              onChange={(e) => {
                this.setState({
                  selectedInstanceSegModelArchitecture: e.target.value,
                });
                this.props.updateFormDataAdvSettings(
                  "comDLArchitecture",
                  instanceSegModelArchitectures.filter(
                    (element) => element.label == e.target.value
                  )[0]
                );
              }}
            >
              {instanceSegModelArchitectures.map((architecture) => (
                <MenuItem key={architecture.label} value={architecture.label}>
                  {architecture.label}
                </MenuItem>
              ))}
            </TextField>
          </React.Fragment>
        )}
        <br />

        <FormLabel component="legend" style={{ marginBottom: "0px" }}>
          Hyperparameters:
        </FormLabel>

        <Grid container spacing={1}>
          <Grid item xs={4}>
            {this.state.isBrightfield ? (
              <FormControl fullWidth>
                <TextField
                  select
                  label="Input channels"
                  size="small"
                  value={formData["advancedSettings"]["in_channels"]}
                  /*onChange={(e) =>
                    this.props.updateFormDataAdvSettings(
                      "in_channels",
                      e.target.value
                    )
                }*/
                >
                  <MenuItem key={3} value={3}>
                    3 (RGB)
                  </MenuItem>
                  {/* <MenuItem key={1} value={1}>
                    1 (grey)
                  </MenuItem> */}
                </TextField>
              </FormControl>
            ) : (
              <FormControl fullWidth>
                <InputLabel>Input channels</InputLabel>
                <Select
                  multiple
                  size="small"
                  label="Input channels"
                  value={formData["advancedSettings"]["flChannels"]}
                  renderValue={(selected) => {
                    if (selected) {
                      return <div>{selected.length}</div>;
                    } else {
                      return <div>0</div>;
                    }
                  }}
                >
                  {histogramConfig.channels.map((channel) => (
                    <MenuItem key={channel.name} value={channel.name}>
                      <Checkbox
                        checked={formData["advancedSettings"][
                          "flChannels"
                        ].includes(channel.name)}
                        onChange={(e) =>
                          this.props.updateFormDataAdvSettingsFlChannels(
                            channel,
                            e.currentTarget.checked
                          )
                        }
                      />
                      <ListItemText
                        primary={channel.name}
                        style={{
                          background: channel.color,
                          paddingLeft: 10,
                        }}
                      />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            <br />
            <br />
            <FormControl component="fieldset" fullWidth>
              <TextField
                name="epochs"
                type="number"
                size="small"
                label="Epochs"
                placeholder="Enter number of epochs..."
                value={formData["advancedSettings"]["epochs"]}
                onChange={(e) =>
                  this.props.updateFormDataAdvSettings("epochs", e.target.value)
                }
                variant="outlined"
                InputProps={{ inputProps: { min: 1, max: 10000 } }}
              />
            </FormControl>
            <br />
            <br />
            <FormControl fullWidth>
              <TextField
                select
                size="small"
                label="Loss function"
                value={formData["advancedSettings"]["lossFunction"]}
                onChange={(e) => {
                  this.props.updateFormDataAdvSettings(
                    "lossFunction",
                    e.target.value
                  );
                }}
              >
                <MenuItem key="Cross Entropy" value="Cross Entropy">
                  Cross Entropy
                </MenuItem>
                <MenuItem key="DICE" value="DICE">
                  DICE
                </MenuItem>
                <MenuItem
                  key="Cross Entropy + DICE"
                  value="Cross Entropy + DICE"
                >
                  Cross Entropy + DICE
                </MenuItem>
              </TextField>
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl fullWidth>
              <TextField
                select
                label="Pyramid level"
                size="small"
                value={formData["advancedSettings"]["level"]}
                disabled={
                  this.props.selectedModel.name !== "newModel" &&
                  !allStructuresEnabled
                }
                onChange={(e) => {
                  this.props.updateFormDataAdvSettings("level", e.target.value);
                }}
              >
                {this.levelDataArray.length > 0 ? (
                  this.levelDataArray.map((levelData) => (
                    <MenuItem key={levelData.level} value={levelData.level}>
                      {levelData.description}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem key={0} value={0}>
                    1 : 1
                  </MenuItem>
                )}
              </TextField>
            </FormControl>
            <br />
            <br />
            <FormControl component="fieldset" fullWidth>
              <TextField
                name="batch_size"
                type="number"
                size="small"
                label="Batch size"
                placeholder="Enter batch size..."
                value={formData["advancedSettings"]["batch_size"]}
                onChange={(e) =>
                  this.props.updateFormDataAdvSettings(
                    "batch_size",
                    e.target.value
                  )
                }
                variant="outlined"
                InputProps={{
                  inputProps: { min: 1, max: 512 },
                }}
              />
            </FormControl>
            <br />
            <br />
            {formData["modelType"] === "segmentation" && (
              <FormControlLabel
                label="Weight classes"
                control={
                  <Checkbox
                    checked={formData["advancedSettings"]["useClassWeights"]}
                    onChange={(e) =>
                      this.props.updateFormDataAdvSettings(
                        "useClassWeights",
                        e.currentTarget.checked
                      )
                    }
                  />
                }
              />
            )}
          </Grid>
          <Grid item xs={4}>
            <React.Fragment>
              <FormControl component="fieldset" fullWidth>
                <TextField
                  name="learning_rate"
                  type="number"
                  size="small"
                  label="Learning rate"
                  placeholder="Enter learning rate..."
                  value={formData["advancedSettings"]["lr"]}
                  onChange={(e) =>
                    this.props.updateFormDataAdvSettings("lr", e.target.value)
                  }
                  variant="outlined"
                  InputProps={{
                    inputProps: { step: 0.0001 },
                  }}
                />
              </FormControl>
              <br />
              <br />
              <FormControl fullWidth>
                <TextField
                  select
                  size="small"
                  label="Optimizer"
                  value={this.getOptimizerIndex(optimizers)}
                  onChange={(e) =>
                    this.props.updateFormDataAdvSettings(
                      "optimizer",
                      optimizers[e.target.value]
                    )
                  }
                >
                  {optimizers.map((optimizer, idx) => (
                    <MenuItem key={uuidv4()} value={idx}>
                      {optimizer}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
              <br />
              <br />
              {formData["modelType"] === "segmentation" && (
                <FormControlLabel
                  label="Include Background"
                  control={
                    <Checkbox
                      checked={
                        formData["advancedSettings"]["includeBackgroundTiles"]
                      }
                      onChange={(e) =>
                        this.props.updateFormDataAdvSettings(
                          "includeBackgroundTiles",
                          e.currentTarget.checked
                        )
                      }
                    />
                  }
                />
              )}
            </React.Fragment>
          </Grid>
        </Grid>

        <br />
        <React.Fragment>
          <FormLabel component="legend" style={{ marginBottom: "0px" }}>
            Dataset Settings:
          </FormLabel>
        </React.Fragment>
        {formData["modelType"] !== "classification" && (
          <React.Fragment>
            <Grid container spacing={1}>
              <Grid item xs={4} style={{ paddingTop: "0px" }}>
                <FormControl fullWidth>
                  <TextField
                    select
                    margin="normal"
                    size="small"
                    label="Dataset approach"
                    value={formData["advancedSettings"]["datasetApproach"]}
                    disabled={
                      this.props.selectedModel.name !== "newModel" &&
                      !allStructuresEnabled
                    }
                    onChange={(e) => {
                      this.props.updateFormDataAdvSettings(
                        "datasetApproach",
                        e.target.value
                      );
                      if (e.target.value === "Object Based") {
                        this.setObjectBasedStructure();
                      }
                    }}
                  >
                    <MenuItem key="Sliding Window" value="Sliding Window">
                      Sliding Window
                    </MenuItem>
                    <MenuItem key="Full Image" value="Full Image">
                      Full Image
                    </MenuItem>
                    <MenuItem key="Object Based" value="Object Based">
                      Object Based
                    </MenuItem>
                  </TextField>
                </FormControl>
              </Grid>
              {["Sliding Window", "Full Image"].includes(
                formData["advancedSettings"]["datasetApproach"]
              ) && (
                <Grid item xs={4} style={{ paddingTop: "0px" }}>
                  <FormControl fullWidth>
                    <TextField
                      name="grid size"
                      margin="normal"
                      type="number"
                      size="small"
                      label="Tile size (px)"
                      placeholder="Enter tile size..."
                      disabled={
                        this.props.selectedModel.name !== "newModel" &&
                        !allStructuresEnabled
                      }
                      value={formData["advancedSettings"]["tileSize"]}
                      onChange={(e) => {
                        let n = parseInt(
                          Math.log2(formData["advancedSettings"]["tileSize"]),
                          10
                        );
                        n =
                          parseInt(e.target.value) >
                          formData["advancedSettings"]["tileSize"]
                            ? n + 1
                            : n - 1;
                        n = n > 6 ? n : 6;
                        let resultValue = Math.pow(2, n);
                        this.props.updateFormDataAdvSettings(
                          "tileSize",
                          resultValue
                        );
                        if (
                          resultValue <
                          this.props.formData.advancedSettings.overlap * 2
                        ) {
                          this.props.updateFormDataAdvSettings(
                            "overlap",
                            parseInt(resultValue / 2, 10)
                          );
                        }
                      }}
                    />
                  </FormControl>
                </Grid>
              )}

              {formData["advancedSettings"]["datasetApproach"] ===
                "Sliding Window" && (
                <Grid item xs={4} style={{ paddingTop: "0px" }}>
                  <FormControl fullWidth>
                    <TextField
                      name="overlap"
                      margin="normal"
                      type="number"
                      size="small"
                      label="Overlap (px)"
                      placeholder="Enter overlap..."
                      value={formData["advancedSettings"]["overlap"]}
                      onChange={(e) =>
                        this.props.updateFormDataAdvSettings(
                          "overlap",
                          e.target.value
                        )
                      }
                    />
                  </FormControl>
                </Grid>
              )}

              {formData["advancedSettings"]["datasetApproach"] ===
                "Object Based" && (
                <Grid item xs={4} style={{ paddingTop: "0px" }}>
                  <TextField
                    select
                    margin="normal"
                    size="small"
                    label="Object based structure"
                    value={
                      formData["advancedSettings"]["objectBasedBaseStructure"]
                    }
                    onChange={(e) =>
                      this.props.updateFormDataAdvSettings(
                        "objectBasedBaseStructure",
                        e.target.value
                      )
                    }
                  >
                    {this.props.structures &&
                      this.props.structures.map((c) => (
                        <MenuItem key={c.id} value={c}>
                          {c.label}
                        </MenuItem>
                      ))}
                  </TextField>
                </Grid>
              )}
            </Grid>
          </React.Fragment>
        )}

        {formData["modelType"] === "classification" && (
          <React.Fragment>
            <FormControl>
              <TextField
                name="grid size"
                margin="normal"
                type="number"
                size="small"
                label="Image size (px)"
                placeholder="Enter image size..."
                value={formData["advancedSettings"]["tileSize"]}
                onChange={(e) => {
                  let n = parseInt(
                    Math.log2(formData["advancedSettings"]["tileSize"]),
                    10
                  );
                  n =
                    parseInt(e.target.value) >
                    formData["advancedSettings"]["tileSize"]
                      ? n + 1
                      : n - 1;
                  n = n > 6 ? n : 6;
                  let resultValue = Math.pow(2, n);
                  this.props.updateFormDataAdvSettings("tileSize", resultValue);
                }}
              />
            </FormControl>
          </React.Fragment>
        )}
        <br />
        <Button onClick={this.props.createDataSet}>
          Create Dataset (no training)
        </Button>
        <FormControlLabel
          label="Use existing dataset"
          style={{ paddingLeft: "20px" }}
          control={
            <Checkbox
              checked={formData["advancedSettings"]["useExistingDataset"]}
              onChange={(e) =>
                this.props.updateFormDataAdvSettings(
                  "useExistingDataset",
                  e.currentTarget.checked
                )
              }
            />
          }
        />
      </div>
    );
  };

  render() {
    const { classes, formData } = this.props;
    const { settingsType } = this.state;

    return (
      <DialogContent className={classes.dialogContent}>
        <DialogContentText
          component={"span"}
          style={{ paddingTop: "0px", marginBottom: "20px" }}
        >
          Use default settings if you are a standard user, use advaned settings
          if you are an expert user.
        </DialogContentText>
        <FormControl component="fieldset">
          <RadioGroup
            aria-label="settings-type"
            name="settings-type"
            defaultValue="default"
            onChange={(e) => {
              this.setState({ settingsType: e.target.value });
            }}
          >
            <FormControlLabel
              value="default"
              control={<Radio />}
              label="Default settings"
            />
            <FormControlLabel
              value="advanced"
              control={<Radio />}
              label="Advanced settings"
            />
          </RadioGroup>
        </FormControl>
        {settingsType === "advanced" && (
          <React.Fragment>
            {formData["modelType"] === "segmentation" &&
              this.renderModelSettings()}
            {formData["modelType"] === "classification" &&
              this.renderModelSettings()}
            {formData["modelType"] === "object detection" &&
              this.renderModelSettings()}
            {formData["modelType"] === "instance segmentation" &&
              this.renderModelSettings()}
          </React.Fragment>
        )}
      </DialogContent>
    );
  }
}

TrainModelStep4.propTypes = {
  classes: PropTypes.object.isRequired,
  formData: PropTypes.object,
  project: PropTypes.object,
  files: PropTypes.array,
  ome: PropTypes.object,
  selectedModel: PropTypes.object,
  onChangeFiles: PropTypes.func,
  updateFormDataAdvSettingsEncoders: PropTypes.func,
  updateFormDataAdvSettings: PropTypes.func,
  updateFormDataAdvSettingsFlChannels: PropTypes.func,
  deleteFormDataAdvSettingsKeys: PropTypes.func,
  createDataSet: PropTypes.func,
  structures: PropTypes.array,
  roiLayers: PropTypes.array,
  histogramConfig: PropTypes.object,
};

export default withStyles(styles)(TrainModelStep4);
