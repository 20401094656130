// Copyright HS-Analysis GmbH, 2019

// Framework imports
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import React, { useRef } from "react";

// External imports
import classNames from "classnames";

// Material UI imports
import { lighten, darken } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import CloudDownload from "@mui/icons-material/CloudDownload";
import CloudUpload from "@mui/icons-material/CloudUpload";
import CustomSvgIcon from "../../globalComponents/CustomSvgIcon";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import FileCopy from "@mui/icons-material/FileCopy";
import IconButton from "@mui/material/IconButton";
import Merge from "@mui/icons-material/MergeType";
import LinearProgress from "@mui/material/LinearProgress";
import Paper from "@mui/material/Paper";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import SearchIcon from "@mui/icons-material/Search";
import Stop from "@mui/icons-material/Stop";
import Tab from "@mui/material/Tab";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import TimelineIcon from "@mui/icons-material/Timeline";
import Tabs from "@mui/material/Tabs";
import TextField from "@mui/material/TextField";
import TimerIcon from "@mui/icons-material/Timer";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import withStyles from "@mui/styles/withStyles";

// font awesome imports
import { faObjectGroup } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// HSA imports
import "./../../css/tooltip.css";
import { convertDate, viewerType } from "../../common/utils/Utils";
import { withSpinloader } from "../../common/components/Spinloader";
import Backend from "../../common/utils/Backend";
import FindFilesDialog, { ProjectActionMode } from "../dialogs/FindFilesDialog";
import CreateTimeLineChartDialog from "../dialogs/CreateTimeLineChartDialog";

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function getSorting(order, orderBy) {
  return order === "desc"
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy);
}

const rows = [
  { id: "readableId", numeric: false, disablePadding: false, label: "ID" },
  { id: "thumbnail", numeric: false, disablePadding: true, label: "Preview" },
  { id: "name", numeric: false, disablePadding: true, label: "Name" },
  { id: "type", numeric: true, disablePadding: false, label: "Method" },
  { id: "state", numeric: true, disablePadding: false, label: "State" },
  {
    id: "creationDateTime",
    numeric: true,
    disablePadding: false,
    label: "Created",
  },
  { id: "actions", numeric: false, disablePadding: false, label: "Actions" },
];

class EnhancedTableHead extends React.Component {
  createSortHandler = (property) => (event) => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const { onSelectAllClick, order, orderBy, numSelected } = this.props;

    return (
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox">
            <Checkbox checked={numSelected > 0} onChange={onSelectAllClick} />
          </TableCell>
          {rows.map(
            (row) => (
              <TableCell
                key={row.id}
                align={row.numeric ? "right" : "left"}
                padding={row.disablePadding ? "none" : "normal"}
                sortDirection={orderBy === row.id ? order : false}
              >
                <Tooltip
                  disableInteractive
                  title="Sort"
                  placement={row.numeric ? "bottom-end" : "bottom-start"}
                  enterDelay={300}
                >
                  <TableSortLabel
                    active={orderBy === row.id}
                    direction={order}
                    onClick={this.createSortHandler(row.id)}
                  >
                    {row.label}
                  </TableSortLabel>
                </Tooltip>
              </TableCell>
            ),
            this
          )}
        </TableRow>
      </TableHead>
    );
  }
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const toolbarStyles = (theme) => ({
  root: {
    display: "grid",
    gridTemplateColumns: "80px 1fr auto",
  },
  highlight:
    theme.palette.mode === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  spacer: {
    flex: "1 1 100%",
  },
  actions: {
    color: theme.palette.text.secondary,
  },
  title: {
    flex: "0 0 auto",
  },
});

/**
 * Toolbar for the projects table
 * @param {Object} props - External properties
 * @param {Object} props.classes - CSS classes
 * @param {number} props.numSelected - Number of selected projects
 * @param {Function} props.onChangeFilter - Callback for filter change
 * @param {Function} props.onCreateTimeLineChartClick - Callback for creating a timeline chart
 * @param {Function} props.onDeleteClick - Callback for deleting selected projects
 * @param {Function} props.onDuplicateSelectedClick - Callback for duplicating selected projects
 * @param {Function} props.onExportClick - Callback for exporting selected projects
 * @param {Function} props.onImportClick - Callback for importing projects
 * @param {Function} props.onMergeResultsClick - Callback for merging selected project results
 * @param {Function} props.onMergeSelectedClick - Callback for merging selected projects
 * @param {Function} props.onRunSelectedClick - Callback for running selected projects
 * @param {Function} props.onTimedRunSelectedClick - Callback for running selected projects within time
 * @param {Array} props.projects - Array of all projects
 * @param {string} props.searchText - Search text for filtering projects
 * @param {Array} props.selected - Array of selected project's ids
 * @param {Object} props.spinloader - Spinloader object
 */
let EnhancedTableToolbar = (props) => {
  const {
    classes,
    numSelected,
    onChangeFilter,
    onCreateTimeLineChartClick,
    onDeleteClick,
    onDuplicateSelectedClick,
    onExportClick,
    onImportClick,
    onMergeResultsClick,
    onMergeSelectedClick,
    onRunSelectedClick,
    onTimedRunSelectedClick,
    projects,
    searchText,
    selected,
  } = props;

  const fileUploader = useRef(null);

  const onChangeFile = (event) => {
    props.spinloader.show();
    event.stopPropagation();
    event.preventDefault();
    onImportClick(event.target.files);
    event.target.value = "";
  };

  /**
   * This function initializes the merge button based on certain conditions.
   *
   * The conditions are whether more than one project is selected and whether all of them are successful.
   * It returns an array of two values: whether the button should be disabled and the tooltip to display.
   *
   * @param {number} numSelected - Number of selected projects
   * @param {Array} projects - Array of all projects
   * @param {Array} selected - Array of selected project's ids
   *
   * @returns {Array} An array containing information about whether the merge
   * button should be disabled and the tooltip to be displayed. First element is a boolean value which
   * tells if the button should be disabled or not and the second element is a string for the tooltip.
   */
  const initMergeButton = (numSelected, projects, selected) => {
    let mergeResultsButtonTooltip = "Merge Results (2 selections required)";
    let mergeResultsButtonDisabled = true;

    if (numSelected > 1) {
      const selectedProjects = projects.filter((project) =>
        selected.includes(project.id)
      );

      const allProjectsFinished = selectedProjects.every(
        (project) => project.state === "success"
      );

      if (!allProjectsFinished) {
        mergeResultsButtonTooltip =
          "Merge Results (All projects must be finished)";
      }

      mergeResultsButtonDisabled = !allProjectsFinished;
    }

    return [mergeResultsButtonDisabled, mergeResultsButtonTooltip];
  };

  /**
   * Initializes the timeline button based on certain conditions.
   *
   * The conditions are whether more than one project is selected, whether all projects are successful,
   * and if they are all of type 'Fungilyzer'. It returns an array of two values:
   * whether the button should be disabled and the tooltip to display.
   *
   * @param {number} numSelected - Number of selected projects
   * @param {Array} projects - Array of all projects
   * @param {Array} selected - Array of selected project's ids
   *
   * @returns {Array} An array containing information about whether the timeline
   * button should be disabled and the tooltip to be displayed. First element is a boolean value which
   * tells if the button should be disabled or not and the second element is a string for the tooltip.
   */
  const initTimelineButton = (numSelected, projects, selected) => {
    let timeLineButtonTooltip = "Create Timeline Chart (2 selections required)";
    let timeLineButtonDisabled = true;
    if (numSelected > 1) {
      const selectedProjects = projects.filter((project) => {
        return selected.includes(project.id);
      });
      const selectedProjectType = selectedProjects[0].type;
      let projectTypeEqual = true;
      let projectTypeSupported = true;
      let allProjectsFinished = true;
      let notSupportedMethod = "";

      const supportedProjectTypes = ["Fungilyzer"];
      // check if all selected projects have the same type
      for (let i = 1; i < selectedProjects.length; i++) {
        if (selectedProjects[i].type !== selectedProjectType) {
          projectTypeEqual = false;
        }
        if (selectedProjects[i].state !== "success") {
          allProjectsFinished = false;
        }
        // check if all selected projects are supported
        if (!supportedProjectTypes.includes(selectedProjects[i].type)) {
          notSupportedMethod = selectedProjects[i].type;
          projectTypeSupported = false;
        }
      }
      if (!allProjectsFinished) {
        timeLineButtonTooltip =
          "Create Timeline Chart (All projects must be finished)";
      } else if (!projectTypeEqual) {
        timeLineButtonTooltip = "Create Timeline Chart (Method must be equal)";
      } else if (!projectTypeSupported) {
        timeLineButtonTooltip =
          "Create Timeline Chart (" + notSupportedMethod + " not supported)";
      } else {
        timeLineButtonDisabled = false;
        timeLineButtonTooltip = "Create Timeline Chart";
      }
    }
    return [timeLineButtonDisabled, timeLineButtonTooltip];
  };

  const [timeLineButtonDisabled, timeLineButtonTooltip] = initTimelineButton(
    numSelected,
    projects,
    selected
  );

  const [mergeResultButtonDisabled, mergeResultButtonTooltip] = initMergeButton(
    numSelected,
    projects,
    selected
  );

  return (
    <Toolbar
      className={classNames(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <div className={classes.title}>
        {numSelected > 0 ? (
          <Typography color="inherit" variant="subtitle1">
            {numSelected} selected
          </Typography>
        ) : (
          <Typography variant="h6" id="tableTitle">
            Projects
          </Typography>
        )}
      </div>
      <div style={{ marginLeft: "20px", width: "360px" }}>
        <SearchIcon
          style={{ display: "inline-block", fill: "lightgray", marginTop: 20 }}
        />
        <TextField
          fullWidth
          variant="standard"
          style={{
            width: "200px",
            marginLeft: 6,
            marginTop: 0,
            display: "inline-block",
          }}
          key={"searchText"}
          margin="normal"
          label={"Search Projects"}
          value={searchText}
          onChange={onChangeFilter}
        />
      </div>
      <div className={classes.actions}>
        {numSelected > 0 ? (
          <div style={{ width: "max-content" }}>
            <Tooltip disableInteractive title={mergeResultButtonTooltip}>
              <span>
                <IconButton
                  disabled={mergeResultButtonDisabled}
                  aria-label="Export"
                  onClick={onMergeResultsClick}
                  size="large"
                >
                  <FontAwesomeIcon icon={faObjectGroup} />
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip disableInteractive title={timeLineButtonTooltip}>
              <span>
                <IconButton
                  disabled={timeLineButtonDisabled}
                  aria-label="Export"
                  onClick={onCreateTimeLineChartClick}
                  size="large"
                >
                  <TimelineIcon />
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip disableInteractive title="Export projects">
              <IconButton
                aria-label="Export"
                onClick={onExportClick}
                size="large"
              >
                <CloudDownload />
              </IconButton>
            </Tooltip>
            <Tooltip
              disableInteractive
              title="Run selected projects within time "
            >
              <IconButton
                aria-label="Timer"
                onClick={onTimedRunSelectedClick}
                size="large"
              >
                <TimerIcon />
              </IconButton>
            </Tooltip>

            <Tooltip disableInteractive title="Merge">
              <span>
                <IconButton
                  aria-label="Merge"
                  onClick={onMergeSelectedClick}
                  size="large"
                  disabled={numSelected < 2}
                >
                  <Merge />
                </IconButton>
              </span>
            </Tooltip>

            <Tooltip disableInteractive title="Duplicate">
              <IconButton
                aria-label="Duplicate"
                onClick={onDuplicateSelectedClick}
                size="large"
              >
                <FileCopy />
              </IconButton>
            </Tooltip>

            <Tooltip disableInteractive title="Run selected projects now">
              <IconButton
                aria-label="Play"
                onClick={onRunSelectedClick}
                size="large"
              >
                <PlayArrowIcon />
              </IconButton>
            </Tooltip>
            <Tooltip disableInteractive title="Delete selected projects">
              <IconButton
                aria-label="Delete"
                onClick={onDeleteClick}
                size="large"
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </div>
        ) : (
          <Tooltip disableInteractive title="Import existing project">
            <IconButton
              aria-label="Import"
              onClick={() => fileUploader.current.click()}
              size="large"
            >
              <CloudUpload />
            </IconButton>
          </Tooltip>
        )}
        <input
          type="file"
          accept=".hsa"
          ref={fileUploader}
          onChange={onChangeFile}
          style={{ display: "none" }}
        />
      </div>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onChangeFilter: PropTypes.func,
  onCreateTimeLineChartClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  onDuplicateSelectedClick: PropTypes.func,
  onExportClick: PropTypes.func,
  onImportClick: PropTypes.func,
  onMergeResultsClick: PropTypes.func,
  onMergeSelectedClick: PropTypes.func,
  onRunSelectedClick: PropTypes.func,
  onTimedRunSelectedClick: PropTypes.func,
  projects: PropTypes.arrayOf(PropTypes.object),
  searchText: PropTypes.string,
  selected: PropTypes.arrayOf(PropTypes.string),
  spinloader: PropTypes.object,
};

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar);

const styles = () => ({
  root: {
    width: "100%",
  },
  table: {
    minWidth: 1020,
  },
  tableRow: {
    cursor: "pointer",
  },
  tableWrapper: {
    overflowX: "auto",
    height: "calc(100vh - 232px)",
  },
  paginationSpacer: {
    flex: "0.0 1 0%",
  },
  creatingJobBar: {
    backgroundColor: darken("#216DC0", 0.3),
  },
  input: {
    width: "calc(100% - 48px)",
  },
  progressBarContainer: {
    position: "relative",
  },
  progressBar: {
    position: "absolute",
    left: 0,
    top: 0,
    width: "100%",
    lineHeight: "20px",
    textAlign: "center",
  },
});

class ProjectsTable extends React.Component {
  constructor(props) {
    super(props);

    this._isMounted = false;
    this.state = {
      order: "desc",
      orderBy: "changeDateTime",
      selected: [],
      page: 0,
      rowsPerPage: 50,
      searchText: "",
    };
  }

  componentDidMount = () => {
    this._isMounted = true;
    Backend.loadAvailableProjectTypes((e) => {
      this.setState({
        projectTypes: e,
        projectTypesNames: e.map((projectType) => projectType.name),
      });
    });
  };

  componentWillUnmount = () => {
    this._isMounted = false;
  };

  setMountedState = (stateObject, callback) => {
    if (this._isMounted) {
      this.setState(stateObject, callback);
    }
  };

  onChangeFilter = (e) => {
    let searchText = e.target.value;
    this.setState({ searchText });
  };

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = "desc";

    if (this.state.orderBy === property && this.state.order === "desc") {
      order = "asc";
    }

    this.setState({ order, orderBy });
  };

  handleSelectAllClick = (event) => {
    if (event.target.checked) {
      this.setState(() => ({
        selected: this.props.projects
          .filter((project) => {
            return this.isJobVisible(project);
          })
          .map((project) => project.id),
      }));
    } else {
      this.setState({ selected: [] });
    }
  };

  handleSelectOneClick = (e, n) => {
    let selectedObject = this.state.selected;
    let selectedIndex = selectedObject.indexOf(n.id);
    if (selectedIndex !== -1) selectedObject.splice(selectedIndex, 1);
    else selectedObject.push(n.id);
    this.setState(() => ({
      selected: selectedObject,
    }));
    e.stopPropagation();
  };

  handleClick = (event, project) => {
    if (!this.props.serverIsRunning) {
      window.showWarningSnackbar("Local API Server not ready!");
      return;
    }
    this.props.spinloader.show();
    Backend.requestProject(
      project.id,
      (res) => {
        this.props.spinloader.hide();
        if (!res.newMappingsFound && res.allUniquelyMapped) {
          this.props.history.push(viewerType(project.type) + project.id);
          return;
        }
        this.FindMissingFiles.show(res, () =>
          this.props.history.push(viewerType(project.type) + project.id)
        );
      },
      (error) => {
        window.openErrorDialog(error);
        this.props.spinloader.hide();
      }
    );
  };

  handleContextmenu = (event, project) => {
    if (!this.isSelected(project.id)) {
      const { selected } = this.state;
      selected.push(project.id);
      this.setState({ selected });
    }
    event.preventDefault();
    return false;
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value });
  };

  isSelected = (id) => this.state.selected.indexOf(id) !== -1;

  /**
   * Runs job for project now
   * @param {MouseEvent} e
   * @param {String} id Project Id
   */
  onRunRowClick(e, id) {
    e.stopPropagation();
    Backend.setProjectsPending([id], () => {
      console.log("project state changed to pending:", id);
    });
  }

  /**
   * Cancels job for project now
   * @param {MouseEvent} e
   * @param {String} id Project Id
   */
  onCancelRowClick(e, id) {
    e.stopPropagation();
    Backend.cancelJob(id);
  }

  /**
   * Set job state for project to timed
   * @param {MouseEvent} e
   * @param {String} id Project Id
   */
  onSetTimedClick(e, id) {
    e.stopPropagation();
    Backend.setProjectsTimed([id], () => {
      console.log("project state changed to timed:", id);
    });
  }

  onRunSelectedClick = () => {
    let projects = this.props.projects;
    let projectIds = [];
    for (let project of projects) {
      for (let id of this.state.selected) {
        if (id === project.id && project.canRun) {
          projectIds.push(id);
        }
      }
    }
    Backend.setProjectsPending(projectIds, () => {
      this.setState({ selected: [] });
    });
  };

  onTimedRunSelectedClick = () => {
    let projects = this.props.projects;
    let projectIds = [];
    for (let project of projects) {
      for (let id of this.state.selected) {
        if (id === project.id && project.canRun) {
          projectIds.push(id);
        }
      }
    }
    Backend.setProjectsTimed(projectIds, () => {
      console.log("projects state changed to timed:", projectIds);
      this.setState({ selected: [] });
    });
  };

  /**
   * Import / Export Projects
   */
  onImportClick = (files) => {
    Backend.importProjects(files[0], (res) => {
      this.ImportProjectsDialog.show(res);
      this.setState({ selected: [] });
      this.props.spinloader.hide();
    });
  };

  onExportClick = () => {
    this.props.spinloader.show();
    let exportName = "";
    let numSelected = 0;
    for (let project of this.props.projects) {
      if (this.state.selected.includes(project.id)) {
        if (numSelected === 0) {
          exportName += project.name;
        }
        numSelected++;
      }
    }
    if (numSelected > 1) {
      exportName += "+" + (numSelected - 1);
    }

    Backend.exportProjects(this.state.selected, exportName, () => {
      this.props.spinloader.hide();
      this.setState({ selected: [] });
    });
  };

  /**
   * Delete selected projects
   */
  onDeleteClick = () => {
    window.openResponseDialog(
      "Do you really want to delete this project?",
      (response) => {
        if (response) {
          Backend.deleteProjects(this.state.selected, () => {
            this.setState({ selected: [] });
            this.props.onResetProjectList(null);
          });
          // get projectnames for selected projectIDs
          let projects = this.props.projects.filter((project) =>
            this.state.selected.includes(project.id)
          );
          let projectNames = [];
          projects.forEach(function (element) {
            projectNames.push(element.name);
          });
          Backend.deleteGalleryExport(projectNames, () => {
            this.setState({ selected: [] });
          });
        }
      }
    );
  };

  onDuplicateSelectedClick = () => {
    Backend.duplicateProjects(this.state.selected, () => {
      this.setState({ selected: [] });
      this.props.handle_active_tab_change(0);
    });
  };

  onMergeSelectedClick = () => {
    let projectType = null;
    let projectTypesEqual = true;
    for (const selectedProjectId of this.state.selected) {
      const selectedProject = this.props.projects.find(
        (project) => project.id === selectedProjectId
      );
      if (projectType === null) {
        projectType = selectedProject.type;
      } else {
        if (projectType !== selectedProject.type) {
          projectTypesEqual = false;
          break;
        }
      }
    }
    if (!projectTypesEqual) {
      window.showErrorSnackbar("Merge not possible. Types are not equal!");
      return;
    }
    this.props.spinloader.show();
    Backend.mergeProjects(this.state.selected)
      .then((result) => {
        if (!result.success) {
          window.showErrorSnackbar("Merge failed!");
        }
        this.setState({ selected: [] });
        this.props.spinloader.hide();
      })
      .catch((error) => {
        console.error("Error merging projects", error);
        window.openErrorDialog("Error merging projects: " + error);
        this.props.spinloader.hide();
      });
  };

  onDuplicateSingleClick = (e, id) => {
    e.stopPropagation();
    Backend.duplicateProjects([id], () => {
      console.log("Project copied!");
      this.props.handle_active_tab_change(0);
    });
  };

  onProjectStateClick = (e, id) => {
    e.stopPropagation();
    Backend.loadProjectMessage(id)
      .then((jsonResponse) => {
        if (jsonResponse.message) {
          console.log(jsonResponse.message);
        }
      })
      .catch((error) => {
        console.log("Error:", error);
      });
  };

  onProjectErrorStateClick = (e, id) => {
    e.stopPropagation();
    Backend.loadProjectMessage(id)
      .then((jsonResponse) => {
        if (jsonResponse.message) {
          window.openErrorDialog(jsonResponse.message);
        }
      })
      .catch((error) => {
        console.log("Error:", error);
      });
  };

  /**
   * Updates label style according to project state
   * @param {Object} project project obejct with state string e.g. error, success, pending, running...
   */
  labelFromState = (project) => {
    const { classes } = this.props;
    switch (project.state) {
      case "creating":
        return (
          <Tooltip disableInteractive title={`Importing: ${project.progress}%`}>
            <div
              className={classes.progressBarContainer}
              onClick={(e) => this.onProjectStateClick(e, project.id)}
            >
              <LinearProgress
                style={{
                  height: 20,
                  width: "100%",
                }}
                classes={{ bar: classes.creatingJobBar }}
                variant="determinate"
                value={project.progress}
              />
              <div className={classes.progressBar}>{project.progress}%</div>
            </div>
          </Tooltip>
        );
      case "running":
        return (
          <Tooltip
            disableInteractive
            title={`Running job: ${project.progress.toFixed(2, 10)}%`}
          >
            <div
              className={classes.progressBarContainer}
              onClick={(e) => this.onProjectStateClick(e, project.id)}
            >
              <LinearProgress
                style={{
                  height: 20,
                  width: "100%",
                }}
                variant="determinate"
                value={project.progress}
              />
              <div className={classes.progressBar}>{project.progress}%</div>
            </div>
          </Tooltip>
        );
      case "success":
        return (
          <span
            style={{ color: "rgb(71, 214, 71)" }}
            onClick={(e) => this.onProjectStateClick(e, project.id)}
          >
            Finished
          </span>
        );
      case "error":
        return (
          <Button
            style={{ color: "#ff0000" }}
            onClick={(e) => this.onProjectErrorStateClick(e, project.id)}
          >
            {project.state}
          </Button>
        );
      default:
        return (
          <span onClick={(e) => this.onProjectStateClick(e, project.id)}>
            {project.state}
          </span>
        );
    }
  };

  /**
   * Returns Label of a project type
   * @param {String} type Project type string
   */
  labelFromProjectType(type) {
    if (!this.state.projectTypes) return type;
    let resultObject = this.state.projectTypes.find((c) => c.name === type);
    return resultObject ? resultObject.label : type;
  }

  handleChange = (event, value) => {
    if (value !== this.props.activeTab) {
      if (value === 1) {
        window.document.title = `Finished - HSA Kit ${window.version}`;
      } else {
        window.document.title = `Projects - HSA Kit ${window.version}`;
      }
      this.props.handle_active_tab_change(value);
      if (this.state.selected.length > 0) {
        this.setState({
          selected: [],
        });
      }
    }
  };

  countFinished(countOther) {
    let result = this.props.projects.length;
    if (this.props.projects.length > 0) {
      result = this.props.projects.reduce(
        (acc, cur) => acc + (cur.state === "success" ? 1 : 0),
        0
      );
    }
    if (countOther) {
      result = this.props.projects.length - result;
    }
    return result;
  }

  isJobVisible = (row) => {
    const { activeTab } = this.props;
    const { searchText } = this.state;
    let showJob = false;
    //filter if pending or finished
    if (searchText !== "") {
      if (!row.name.toLowerCase().includes(searchText.toLowerCase())) {
        return false;
      }
    }
    if (activeTab === 1) {
      showJob = row.state === "success";
    } else {
      showJob = row.state !== "success";
    }
    if (this.state.projectTypesNames) {
      //filter if job project module exists (release version)
      if (showJob) {
        return this.state.projectTypesNames.includes(row.type);
      } else {
        return false;
      }
    }
    return false;
  };

  openCreateTimeLineDialog = () => {
    this.setState({ createTimeLineChartDialogIsOpen: true });
  };

  onMergeResultsClick = () => {
    this.props.spinloader.show();
    Backend.mergeProjectResults(this.state.selected)
      .then((jsonResponse) => {
        this.props.history.push("/merged_report/" + jsonResponse.projectsDirId);
        this.props.spinloader.hide();
      })
      .catch((error) => {
        console.error("Error merging results", error);
        window.openErrorDialog("Error merging results");
        this.props.spinloader.hide();
      });
  };

  closeCreateTimeLineDialog = () => {
    this.setState({ createTimeLineChartDialogIsOpen: false });
  };

  render() {
    const { classes, projects, activeTab } = this.props;
    const {
      order,
      orderBy,
      selected,
      rowsPerPage,
      page,
      searchText,
      createTimeLineChartDialogIsOpen = false,
    } = this.state;

    const sortedProjects = stableSort(
      projects,
      getSorting(order, orderBy)
    ).filter((row) => {
      return this.isJobVisible(row);
    });

    return (
      <Paper className={classes.root}>
        <FindFilesDialog
          ref={(c) => (this.ImportProjectsDialog = c)}
          projectActionMode={ProjectActionMode.Import}
        />
        <FindFilesDialog
          ref={(c) => (this.FindMissingFiles = c)}
          projectActionMode={ProjectActionMode.Update}
        />
        <CreateTimeLineChartDialog
          open={createTimeLineChartDialogIsOpen}
          close={this.closeCreateTimeLineDialog}
          selectedProjectIds={this.state.selected}
          projects={projects}
        />
        <Tabs
          variant="fullWidth"
          indicatorColor="primary"
          textColor="primary"
          value={activeTab}
          onChange={this.handleChange}
        >
          <Tab key="0" label={"Pending (" + this.countFinished(true) + ")"} />
          <Tab key="1" label={"Finished (" + this.countFinished(false) + ")"} />
        </Tabs>
        <EnhancedTableToolbar
          numSelected={selected.length}
          onChangeFilter={this.onChangeFilter}
          onCreateTimeLineChartClick={this.openCreateTimeLineDialog}
          onDeleteClick={this.onDeleteClick}
          onDuplicateSelectedClick={this.onDuplicateSelectedClick}
          onDuplicateSingleClick={this.onDuplicateSingleClick}
          onExportClick={this.onExportClick}
          onImportClick={this.onImportClick}
          onMergeResultsClick={this.onMergeResultsClick}
          onMergeSelectedClick={this.onMergeSelectedClick}
          onRunSelectedClick={this.onRunSelectedClick}
          onTimedRunSelectedClick={this.onTimedRunSelectedClick}
          projects={projects}
          searchText={searchText}
          selected={selected}
          spinloader={this.props.spinloader}
        />

        <div className={classes.tableWrapper}>
          <Table className={classes.table} style={{ tableLayout: "fixed" }}>
            <colgroup>
              <col width="50px" />
              <col width="50px" />
              <col width="70px" />
              <col />
              <col width="250px" />
              <col width="180px" />
              <col width="250px" />
              <col width="130px" />
            </colgroup>
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={this.handleSelectAllClick}
              onRequestSort={this.handleRequestSort}
              rowCount={projects.length}
              activeTab={this.props.activeTab}
              spinloader={this.props.spinloader}
            />
            <TableBody>
              {sortedProjects.map((n) => {
                const isSelected = this.isSelected(n.id);
                return (
                  <TableRow
                    className={classes.tableRow}
                    hover
                    onClick={(event) => this.handleClick(event, n)}
                    role="checkbox"
                    aria-checked={isSelected}
                    tabIndex={-1}
                    key={n.id}
                    selected={isSelected}
                    onContextMenu={(event) => this.handleContextmenu(event, n)}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={isSelected}
                        onClick={(event) => this.handleSelectOneClick(event, n)}
                      />
                    </TableCell>
                    <TableCell align="right">{n.readableId}</TableCell>
                    <TableCell style={{ padding: "0" }}>
                      <Tooltip
                        disableInteractive
                        title={
                          <React.Fragment>
                            {n.files
                              .slice(0, Math.min(5, n.files.length))
                              .map((file) => {
                                return (
                                  <div
                                    style={{ display: "inline-block" }}
                                    key={file.id}
                                  >
                                    <div style={{ display: "inline-block" }}>
                                      {
                                        <img
                                          style={{
                                            display: "block",
                                            background: "black",
                                            margin: "5px",
                                            objectFit: "contain",
                                          }}
                                          width="200"
                                          height="200"
                                          src={Backend.renderThumbnail(file.id)}
                                          alt=""
                                        />
                                      }
                                      <div
                                        style={{
                                          width: "100%",
                                          textAlign: "center",
                                        }}
                                      >
                                        {file.fileName}
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                          </React.Fragment>
                        }
                      >
                        {
                          <img
                            style={{
                              background: "black",
                              margin: "2px",
                              objectFit: "contain",
                            }}
                            onError={(event) =>
                              (event.target.style.display = "none")
                            }
                            width="60"
                            height="60"
                            src={Backend.renderThumbnail(
                              n.files.length > 0 ? n.files[0].id : 0
                            )}
                            alt=""
                          />
                        }
                      </Tooltip>
                    </TableCell>
                    <TableCell component="th" scope="row" padding="none">
                      <div
                        style={{
                          display: "inline-block",
                          width: "calc(100% - 48px)",
                        }}
                      >
                        {n.name}
                      </div>
                      {!n.type.includes("HistoPointCounting") &&
                        !n.type.includes("HistoClassification") && (
                          <IconButton
                            aria-label="Edit Project Name"
                            onClick={(e) => {
                              this.props.onEditProject(n);
                              e.stopPropagation();
                            }}
                            size="large"
                          >
                            <EditIcon />
                          </IconButton>
                        )}
                    </TableCell>
                    <TableCell align="right">
                      {this.labelFromProjectType(n.type)}
                    </TableCell>
                    <TableCell align="right">
                      {this.labelFromState(n)}
                    </TableCell>
                    <TableCell align="right">
                      {convertDate(n.creationDateTime).toLocaleString()}
                    </TableCell>
                    <TableCell align="right">
                      {n.state === "success" &&
                      n.type !== "ProteomeAnalysis" ? (
                        <Tooltip disableInteractive title="Result">
                          <span>
                            <IconButton
                              aria-label="Result"
                              onClick={(e) => {
                                e.stopPropagation();
                                this.props.history.push("/report/" + n.id);
                              }}
                              size="large"
                            >
                              <CustomSvgIcon
                                name="ResultsIcon"
                                width="22.86"
                                height="23.8"
                                vertAlign="-4px"
                              />
                            </IconButton>
                          </span>
                        </Tooltip>
                      ) : (
                        ""
                      )}
                      {n.state === "success" && (
                        <Tooltip disableInteractive title="Duplicate project">
                          <span>
                            <IconButton
                              aria-label="Duplicate Project"
                              onClick={(e) =>
                                this.onDuplicateSingleClick(e, n.id)
                              }
                              size="large"
                            >
                              <FileCopy />
                            </IconButton>
                          </span>
                        </Tooltip>
                      )}

                      {!n.type.includes("HistoPointCounting") &&
                        !n.type.includes("HistoClassification") && (
                          <span>
                            {n.state === "running" ||
                            n.state === "timed" ||
                            n.state === "pending" ? (
                              <Tooltip disableInteractive title="Cancel job">
                                <span>
                                  <IconButton
                                    aria-label="Stop"
                                    onClick={(e) =>
                                      this.onCancelRowClick(e, n.id)
                                    }
                                    size="large"
                                  >
                                    <Stop />
                                  </IconButton>
                                </span>
                              </Tooltip>
                            ) : (
                              <span>
                                {!n.canRun ||
                                n.state === "running" ||
                                n.state === "pending" ? (
                                  <IconButton
                                    disabled={true}
                                    aria-label="Timer"
                                    onClick={(e) =>
                                      this.onSetTimedClick(e, n.id)
                                    }
                                    size="large"
                                  >
                                    <TimerIcon />
                                  </IconButton>
                                ) : (
                                  <Tooltip
                                    disableInteractive
                                    title="Run project within time "
                                  >
                                    <IconButton
                                      disabled={false}
                                      aria-label="Timer"
                                      hidden={
                                        n.state === "success" &&
                                        n.type.includes("SonyQD")
                                          ? true
                                          : false
                                      }
                                      onClick={(e) =>
                                        this.onSetTimedClick(e, n.id)
                                      }
                                      size="large"
                                    >
                                      <TimerIcon />
                                    </IconButton>
                                  </Tooltip>
                                )}
                                <Tooltip
                                  disableInteractive
                                  title={
                                    n.state === "success"
                                      ? "Run job (including manual adjustments)"
                                      : "Run job"
                                  }
                                >
                                  <span>
                                    <IconButton
                                      aria-label="Play"
                                      disabled={
                                        !n.canRun ||
                                        n.state === "running" ||
                                        n.state === "pending"
                                      }
                                      hidden={
                                        n.state === "success" &&
                                        n.type.includes("SonyQD")
                                          ? true
                                          : false
                                      }
                                      onClick={(e) =>
                                        this.onRunRowClick(e, n.id)
                                      }
                                      size="large"
                                    >
                                      <PlayArrowIcon />
                                    </IconButton>
                                  </span>
                                </Tooltip>
                              </span>
                            )}
                          </span>
                        )}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </div>
        <TablePagination
          rowsPerPageOptions={[50, 100, 250]}
          classes={{ spacer: classes.paginationSpacer }}
          component="div"
          count={this.countFinished(activeTab === 0)}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            "aria-label": "Previous Page",
          }}
          nextIconButtonProps={{
            "aria-label": "Next Page",
          }}
          onPageChange={this.handleChangePage}
          onRowsPerPageChange={this.handleChangeRowsPerPage}
        />
      </Paper>
    );
  }
}

ProjectsTable.propTypes = {
  activeTab: PropTypes.number,
  classes: PropTypes.object.isRequired,
  handle_active_tab_change: PropTypes.func,
  history: PropTypes.object,
  onEditProject: PropTypes.func,
  onResetProjectList: PropTypes.func,
  projects: PropTypes.array,
  serverIsRunning: PropTypes.bool,
  spinloader: PropTypes.object,
};

export default withRouter(withSpinloader(withStyles(styles)(ProjectsTable)));
